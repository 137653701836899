import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "accordion-item"
};
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
    class: _normalizeClass({
      'active': $data.active
    }),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.toggle && $options.toggle(...args))
  }, _toDisplayString($props.question.question), 3), _createElementVNode("div", {
    class: _normalizeClass(["content", {
      'active': $data.active
    }])
  }, [_createElementVNode("p", {
    innerHTML: $props.question.answer
  }, null, 8, _hoisted_2)], 2)]);
}