import QuestionList from "@/components/employee/category/QuestionList.vue";
import Subcategory from "@/components/employee/category/Subcategory.vue";
import Wishes from "@/components/employee/Wishes.vue";
import Summary from "@/components/employee/Summary.vue";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import api from '@/api';
import question from "../shared/faq/Question.vue";
export default {
  computed: {
    question() {
      return question;
    },

    ...mapGetters('user', ['user']),
    ...mapGetters('questionnaire', ['step']),

    showIntro() {
      return this.$route.params.slug && ['1', '13'].includes(this.$route.params.slug);
    }

  },
  methods: { ...mapMutations('questionnaire', ['SET_STEP']),
    ...mapActions('questionnaire', ['validate']),

    save_and_quit() {
      return this.$router.push('/profiel');
    },

    async next_question(overview = false) {
      console.log('test');
      this.saving = true;
      console.log('test2');
      let validated = await this.validate();
      console.log('test3');

      if (!validated) {
        this.saving = false;
        return false;
      }

      console.log('test4');
      api.post(`/api/categories/${this.$route.params.slug}/save_answers`, {
        step: this.step,
        current_subcategory: this.current_subcategory,
        current_step: this.current_step
      }).then(response => {
        const data = response.data;

        if (data.status && data.status == "completed_category") {
          if (this.$route.params.slug == 2) {
            return this.$router.push("/categorie/2/samenvatting");
          }

          let flash_message = '';
          let route = '/profiel';

          if (this.$route.params.slug == 1) {
            flash_message = 'Persoonlijke gegevens succesvol opgeslagen.';
            route = '/instellingen';
            localStorage.setItem('flash', flash_message);
            this.$router.go(-1);
          }

          if (this.$route.params.slug == 13) {
            flash_message = 'Carrière informatie succesvol opgeslagen.';
            route = '/instellingen';
            localStorage.setItem('flash', flash_message);
            this.$router.go(-1);
          }

          if (this.$route.params.slug == 8) {
            flash_message = 'Jouw kwaliteiten zijn succesvol opgeslagen. Je hebt nu een Wishes profiel!';
          }

          localStorage.setItem('flash', flash_message);
          return this.$router.push(route);
        }

        this.current_subcategory = data.current_subcategory;
        this.current_step = data.current_step;
        this.total_steps = data.total_steps;
        this.subcategories = data.subcategories;
        this.subcategory = this.subcategories[this.current_subcategory];
        this.SET_STEP({
          step: data.step
        });
        this.saving = false;

        if (overview) {
          return this.$router.push('/profiel');
        }
      }).catch(error => alert('Categories.vue - save_answers ' + error));
    },

    prev_question() {
      this.saving = true;
      api.post(`/api/categories/${this.$route.params.slug}/prev_step`, {
        current_subcategory: this.current_subcategory,
        current_step: this.current_step
      }).then(response => {
        const data = response.data;

        if (data === false) {
          return this.$router.push("/categorie/2/intro");
        }

        this.current_subcategory = data.current_subcategory;
        this.current_step = data.current_step;
        this.total_steps = data.total_steps;
        this.subcategories = data.subcategories;
        this.subcategory = this.subcategories[this.current_subcategory];
        this.SET_STEP({
          step: data.step
        });
        this.saving = false;
      }).catch(error => alert('Categories.vue - prev_step ' + error));
    }

  },

  data() {
    var _this$$route$params$s, _this$$route$params$s2;

    return {
      saving: false,
      category: null,
      subcategories: [],
      subcategory: [],
      current_subcategory: (_this$$route$params$s = this.$route.params.subcat) !== null && _this$$route$params$s !== void 0 ? _this$$route$params$s : 0,
      current_step: (_this$$route$params$s2 = this.$route.params.step) !== null && _this$$route$params$s2 !== void 0 ? _this$$route$params$s2 : 0,
      total_steps: 1,
      summary: [],
      openedCategoryDropdown: false
    };
  },

  mounted() {
    this.$store.state.loading = true;

    if (this.$route.name == "summary") {
      api.get(`/api/categories/summary`).then(response => {
        this.category = response.data.category;
        this.subcategories = response.data.subcategories;
        this.subcategory = this.subcategories[this.subcategories.length - 1];
        this.total_steps = response.data.total_steps;
        this.current_subcategory = this.subcategories.length - 1;
        this.current_step = response.data.current_step;
        this.summary = response.data.summary;
        this.summary.forEach((summary, index) => {
          this.summary[index].questions = summary.questions.sort((a, b) => a.sort_order - b.sort_order);
        });
        this.SET_STEP({
          step: response.data.step
        });
        this.$store.state.loading = false;
      }).catch(error => alert('Categories.vue - get_summary ' + error));
    } else {
      api.get(`/api/categories/${this.$route.params.slug}/${this.current_subcategory}/${this.current_step}`).then(response => {
        this.category = response.data.category;
        this.subcategories = response.data.subcategories;
        this.subcategory = this.subcategories[this.current_subcategory];
        this.total_steps = response.data.total_steps;
        this.SET_STEP({
          step: response.data.step
        });
        this.$store.state.loading = false;
      }).catch(error => alert('Categories.vue - get_category ' + error));
    }
  },

  components: {
    QuestionList,
    Subcategory,
    Wishes,
    Summary
  }
};