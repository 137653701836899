export default {
  computed: {
    stepArr() {
      return this.steps[this.currentStep];
    },

    step() {
      return this.steps[this.currentStep][this.currentSubStep];
    }

  },
  methods: {
    nextStep() {
      // if (this.currentStep + 1 == this.steps.length) {
      //     return this.currentStep = 0;
      // }
      if (this.steps[this.currentStep][this.currentSubStep + 1]) {
        return this.currentSubStep++;
      }

      this.currentStep++;
      this.currentSubStep = 0;
    },

    prevStep() {
      this.currentStep--;
      this.currentSubStep = 0;
    },

    goTo(index) {
      this.currentSubStep = index;
    }

  },

  data() {
    return {
      currentStep: 0,
      currentSubStep: 0,
      steps: [[{
        image: "stap1.1.png",
        title: "Het beginscherm.",
        content: "Zie in één opslag wat je kan doen."
      }, {
        image: "stap1.2.gif",
        title: "Het beginscherm.",
        content: "Houd daarnaast oog op je doel(en)."
      }, {
        image: "stap1.3.gif",
        title: "Het beginscherm.",
        content: "Registreer wanneer je een wens wil realiseren."
      }, {
        image: "stap1.4.png",
        title: "Het beginscherm.",
        content: "En houd eenvoudig de aandacht erop."
      }], [{
        image: "stap2.gif",
        title: "Persoonlijk profiel.",
        content: "Het 1<sup>e</sup> onderdeel van het profiel is jouw persoonlijk profiel. De informatie die je hier opgeeft zorgt ervoor dat de communicatie tussen jou en Wishes van start gaat."
      }], [{
        image: "stap3.1.gif",
        title: "Registreren van wensen.",
        content: "Het 2<sup>e</sup> onderdeel van het profiel dat zijn jouw wensen."
      }, {
        image: "stap3.2.gif",
        title: "Registreren van wensen.",
        content: "Je registreert wensen door vragen te beantwoorden in vier categorieën."
      }, {
        image: "stap3.3.gif",
        title: "Registreren van wensen.",
        content: "Voor inspiratie klik je op ‘Help mij met deze wens’."
      }], [{
        image: "stap4.1.gif",
        title: "Presenteer jezelf.",
        content: "Het 3<sup>e</sup> onderdeel van het profiel dat zijn jouw kwaliteiten."
      }, {
        image: "stap4.2.png",
        title: "Presenteer jezelf.",
        content: "Op basis van jouw gewenste functie(s) krijg je vragen gesteld."
      }, {
        image: "stap4.3.gif",
        title: "Presenteer jezelf.",
        content: "De keuzehulp geeft richting en helpt om jouw profiel op te bouwen."
      }, {
        image: "stap4.4.gif",
        title: "Presenteer jezelf.",
        content: "Licht bij de laatste vraag toe wat je aan kennis en vaardigheden geclaimd hebt."
      }], [{
        image: "stap5.1.gif",
        title: "Je hebt een Wishes profiel!",
        content: "Zijn alle onderdelen van het profiel gevuld dan heb je een Wishes profiel. Volledig gebaseerd op jouw wensen en de kennis en vaardigheden die je daarvoor terug wil geven."
      }, {
        image: "stap5.2.gif",
        title: "Je hebt een Wishes profiel!",
        content: "Het traditionele CV heeft een beter alternatief gekregen."
      }], [{
        image: "stap6.1.gif",
        title: "Beoordeel je kansen.",
        content: "Op basis van jouw Wishes profiel ga je kansen ontvangen die ervoor zorgen dat wensen gerealiseerd worden."
      }, {
        image: "stap6.2.gif",
        title: "Beoordeel je kansen.",
        content: "Beoordeel de tips en adviezen van Wishes of bekijk het aanbod van een werkgever die interesse toont om aan jouw wensen te voldoen."
      }], [{
        image: "stap7.gif",
        title: "Contact ons.",
        content: "Wil je ergens jouw mening over geven, heb je een vraag of hulp nodig? Stuur een mail naar <a href='mailto:klantenservice@wishes.work' target='_blank'>klantenservice@wishes.work</a>. We reageren op werkdagen binnen 24 uur."
      }]]
    };
  }

};