import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: "Address",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },

  data() {
    return {
      postcodeErr: false
    };
  },

  computed: { ...mapState('questionnaire', ['answers']),
    ...mapGetters('questionnaire', ['step']),

    hasAnswer() {
      if (!this.answers) return false;
      return this.answers.filter(answer => answer.question_id == this.step.id).length > 0;
    },

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answer: {
      get: function () {
        console.log(this.question);
        return this.question.user_question_answer.answer;
      },
      set: function (new_value) {
        const answer = {
          question_id: this.question_id,
          answer: new_value,
          is_current: this.hide_title
        };
        console.log(answer);
        this.UPDATE_ANSWER(answer);
      }
    }
  },
  methods: { ...mapMutations('questionnaire', ['CREATE_EMPTY_ANSWER', 'UPDATE_ANSWER'])
  }
};