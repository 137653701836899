import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Select2 from 'vue3-select2-component';
import api from '@/api';
export default {
  data() {
    return {
      value: "",
      professions: [],
      settings: {
        tags: true
      }
    };
  },

  name: "Professions",
  // props: ["index"],
  props: {
    index: Number,
    modelValue: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapGetters('questionnaire', ['step']),

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    profession: {
      get: function () {
        var _this$question;

        return (_this$question = this.question) === null || _this$question === void 0 ? void 0 : _this$question.user_question_answer.answer;
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: this.question_id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    }
  },
  components: {
    Select2
  },

  // mounted() {
  beforeCreate() {
    api.get('/api/professions').then(response => {
      this.professions = response.data.map(profession => {
        return {
          id: profession.id,
          text: profession.name
        };
      });
    }).catch(error => alert('Professions.vue - get_professions ' + error));
  }

};