import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "box"
};
const _hoisted_3 = {
  class: "question-row"
};
const _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WishHelp = _resolveComponent("WishHelp");

  return _openBlock(), _createElementBlock("div", null, [!$options.hasSideBar ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.step[0].question_type_text), {
    question_id: _ctx.step[0].id,
    index: 0
  }, null, 8, ["question_id"])), _ctx.step[0].description ? (_openBlock(), _createBlock(_component_WishHelp, {
    key: 0
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.step[0].description), 1)]),
    _: 1
  })) : _createCommentVNode("", true)])])])])) : _createCommentVNode("", true), $options.hasSideBar ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.step[0].question_type_text), {
    question_id: _ctx.step[0].id,
    index: 0
  }, null, 8, ["question_id"]))])) : _createCommentVNode("", true)]);
}