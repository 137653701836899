import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2e33e6c2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 1,
  class: "cta-button"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");

  return $props.to ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: $props.to,
    class: "cta-button"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["to"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]));
}