import api from "@/api";
export default {
  name: "PublicProfile",

  data() {
    return {
      summary: [],
      primary: [],
      basic: [],
      qualities: [],
      professions: []
    };
  } //   methods: {
  //       parseAnswer(answer) {
  //           try {
  //               answer = JSON.parse(answer);
  //               return answer.join(", ");
  //           }
  //           catch(e) {
  //               return answer;
  //           }
  //       },
  //       parseAnswerOrTags(answer) {
  //           try {
  //               answer = JSON.parse(answer);
  //               let response = '<div class="tags">';
  //               response += answer.map(a => `<span>${a}</span>`).join('')
  //               response += '</div>';
  //               return response;
  //           }
  //           catch(e) {
  //               return '<p>' + answer + '</p>';
  //           }
  //       },
  //       parseName(name) {
  //         if (name == "Primair" || name == "Secundair") {
  //           return `${name}e wensen`
  //         }
  //         return name
  //       },
  //       parseImportant(important) {
  //         if (important) return "Ja";
  //         return "Nee";
  //       },
  //       checkIsSame(a, b) {
  //         if (a == b) return true
  //         return false
  //       }
  //   },
  //   mounted() {
  //     this.$store.state.loading = true;
  //     api
  //         .get(`/api/user/public-profile`)
  //         .then(response => {
  //           this.summary = response.data.summary;
  //           this.primary = response.data.primary;
  //           this.basic = response.data.basic;
  //           this.qualities = response.data.qualities;
  //           this.professions = response.data.professions;
  //           this.$store.state.loading = false;
  //         })
  //         .catch( error => alert('Categories.vue - get_summary ' + error) );
  //   }


};