import { mapActions, mapState } from 'vuex';
export default {
  methods: { ...mapActions('user', ['resetPassword']),

    formSubmit() {
      this.resetPassword(this.user.email);
    }

  },
  computed: { ...mapState('user', ['loading', 'success']),

    button_text() {
      if (this.loading) return "Aan het verwerken...";
      return "Wachtwoord resetten";
    }

  },

  data() {
    return {
      user: {
        email: ''
      },
      siteUrl: process.env.VUE_APP_SITE_URL
    };
  },

  mounted() {}

};