import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      value: "",
      selectedValues: [],
      loaded: false
    };
  },

  name: "MultipleChoiceMulti",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapGetters('questionnaire', ['step']),

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answerField() {
      return this.hide_title ? "current" : "answer";
    }

  },
  methods: { ...mapActions('questionnaire', ['validate']),
    ...mapMutations('questionnaire', ['CREATE_EMPTY_ANSWER', 'UPDATE_ANSWER']),

    getFieldName(answer_id) {
      if (this.hide_title) return "answer_current_" + answer_id;
      return "answer_" + answer_id;
    }

  },

  mounted() {
    try {
      let savedAnswers = JSON.parse(this.question.user_question_answer[this.answerField]);
      savedAnswers = savedAnswers.filter(savedAnswer => !!this.question.answers.find(answer => answer.answer.trim() === savedAnswer.trim()));
      this.question.user_question_answer[this.answerField] = savedAnswers;
    } catch (e) {
      this.question.user_question_answer[this.answerField] = [];
    }

    if (!Array.isArray(this.question.user_question_answer[this.answerField])) {
      this.question.user_question_answer[this.answerField] = [];
    }
  }

};