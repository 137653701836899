import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "box"
};
const _hoisted_2 = {
  class: "question-row"
};
const _hoisted_3 = {
  class: "has-icon"
};
const _hoisted_4 = {
  key: 0,
  class: "hartje"
};
const _hoisted_5 = {
  key: 1,
  class: "label",
  for: ""
};
const _hoisted_6 = {
  key: 0,
  class: "error"
};
const _hoisted_7 = {
  class: "error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$question, _$options$question2, _$options$question3, _$options$question4, _$options$question5, _$options$question6, _$options$question7, _$options$question8, _$options$question9;

  const _component_MultipleSelect = _resolveComponent("MultipleSelect");

  const _component_WishHelp = _resolveComponent("WishHelp");

  const _component_DecisionAid = _resolveComponent("DecisionAid");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, [_ctx.$route.params.slug == 2 && !$props.hide_title ? (_openBlock(), _createElementBlock("i", _hoisted_4)) : _createCommentVNode("", true), !$props.hide_title ? (_openBlock(), _createElementBlock("label", _hoisted_5, [(_$options$question = $options.question) !== null && _$options$question !== void 0 && _$options$question.required ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass({
      'error': (_$options$question2 = $options.question) === null || _$options$question2 === void 0 ? void 0 : _$options$question2.error
    })
  }, "* ", 2)) : _createCommentVNode("", true), _createElementVNode("b", null, _toDisplayString((_$options$question3 = $options.question) === null || _$options$question3 === void 0 ? void 0 : _$options$question3.question), 1), (_$options$question4 = $options.question) !== null && _$options$question4 !== void 0 && _$options$question4.required ? (_openBlock(), _createElementBlock("em", {
    key: 1,
    class: _normalizeClass({
      'error': (_$options$question5 = $options.question) === null || _$options$question5 === void 0 ? void 0 : _$options$question5.error
    })
  }, " (verplicht)", 2)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), !$props.hide_title ? _withDirectives((_openBlock(), _createElementBlock("p", {
    key: 2,
    class: "description-sm"
  }, _toDisplayString((_$options$question6 = $options.question) === null || _$options$question6 === void 0 ? void 0 : _$options$question6.short_question_description), 513)), [[_vShow, (_$options$question7 = $options.question) === null || _$options$question7 === void 0 ? void 0 : _$options$question7.short_question_description]]) : _createCommentVNode("", true)]), _createElementVNode("div", null, [_createVNode(_component_MultipleSelect, {
    disableInput: $data.disableInput,
    options: $data.educations,
    onChangedValue: $options.changedValue,
    initValue: $options.education,
    settings: $options.settings,
    placeholder: (_$options$question8 = $options.question) === null || _$options$question8 === void 0 ? void 0 : _$options$question8.placeholder,
    count: _ctx.count,
    hasUnusable: "true"
  }, null, 8, ["disableInput", "options", "onChangedValue", "initValue", "settings", "placeholder", "count"]), (_$options$question9 = $options.question) !== null && _$options$question9 !== void 0 && _$options$question9.error ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($options.question.error), 1)) : _createCommentVNode("", true), _withDirectives(_createElementVNode("p", _hoisted_7, "Je hebt het maximum van 10 items bereikt.", 512), [[_vShow, $data.disableInput]])]), _ctx.step[0].description ? (_openBlock(), _createBlock(_component_WishHelp, {
    key: 0
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.step[0].description), 1)]),
    _: 1
  })) : _createCommentVNode("", true)])]), _createVNode(_component_DecisionAid, {
    disableInput: $data.disableInput,
    style: {
      "margin-top": "16px"
    },
    professions: $data.suggested_educations,
    modelValue: $data.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.selected = $event),
    onChangeval: $options.changeval,
    onDecisionaid: $options.useDecisionAid
  }, null, 8, ["disableInput", "professions", "modelValue", "onChangeval", "onDecisionaid"])]);
}