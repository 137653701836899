import Address from "@/components/employee/category/question_types/Address.vue";
import DateOfBirth from "@/components/employee/category/question_types/DateOfBirth.vue";
import MultipleChoice from "@/components/employee/category/question_types/MultipleChoice.vue";
import Open from "@/components/employee/category/question_types/Open.vue";
import YesNo from "@/components/employee/category/question_types/YesNo.vue";
import Phone from "@/components/employee/category/question_types/Phone.vue";
import Number from "@/components/employee/category/question_types/Number.vue";
import Days from "@/components/employee/category/question_types/Days.vue";
import MultipleChoiceMulti from "@/components/employee/category/question_types/MultipleChoiceMulti.vue";
import Properties from "@/components/employee/category/question_types/Properties.vue";
import Professions from "@/components/employee/category/question_types/Professions.vue";
import ProfessionsMulti from "@/components/employee/category/question_types/ProfessionsMulti.vue";
import Textarea from "@/components/employee/category/question_types/Textarea.vue";
import Educations from "@/components/employee/category/question_types/Educations.vue";
import Competences from "@/components/employee/category/question_types/Competences.vue";
import CompetencesExplanation from "@/components/employee/category/question_types/CompetencesExplanation.vue";
import Skills from "@/components/employee/category/question_types/Skills.vue";
import PostalCodeTravelTime from "@/components/employee/category/question_types/PostalCodeTravelTime.vue";
import WishHelp from '../inputs/Wish-Help.vue';
import { mapGetters, mapState } from 'vuex';
export default {
  name: "single",
  computed: { ...mapGetters('questionnaire', ['step']),

    hasSideBar() {
      const sideBoxComponents = ["Educations", "Competences", "CompetencesExplanation", "Skills"];
      return sideBoxComponents.includes(this.step[0].question_type_text);
    }

  },
  methods: {},
  props: ["index", "key"],
  components: {
    WishHelp,
    Address,
    DateOfBirth,
    MultipleChoice,
    Open,
    YesNo,
    Phone,
    Number,
    Days,
    MultipleChoiceMulti,
    Properties,
    Professions,
    ProfessionsMulti,
    Textarea,
    Educations,
    Competences,
    CompetencesExplanation,
    Skills,
    PostalCodeTravelTime
  }
};