import ProgressBar from "@/components/shared/misc/ProgressBar.vue";
import api from "@/api";
export default {
  name: "PercentageWidget",
  components: {
    ProgressBar
  },
  props: ['percentage', 'name', 'link'],
  computed: {
    percentageComputed() {
      return this.$props.name === 'Doelen' ? this.$props.percentage * 100 : this.$props.percentage;
    },

    linkText() {
      return this.percentageComputed == 100 ? 'Aanpassen' : 'Ga verder';
    }

  }
};