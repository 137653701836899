import { mapGetters, mapMutations } from "vuex";
import api from '@/api';
import PercentageWidget from "@/components/shared/misc/PercentageWidget.vue";
import CtaButton from "@/components/shared/misc/CtaButton.vue";
import ProfileVisitsGraph from "@/components/employee/partials/profileVisitsGraph.vue";
import MainModal from "@/components/shared/misc/MainModal.vue";
import MultipleSelect from "@/components/shared/misc/MultipleSelect.vue";
import WishHelp from "@/components/employee/category/inputs/Wish-Help.vue";
export default {
  name: "Dashboard",
  components: {
    WishHelp,
    MultipleSelect,
    MainModal,
    ProfileVisitsGraph,
    CtaButton,
    PercentageWidget
  },
  ...mapGetters('user', ['user']),

  data() {
    return {
      dateError: false,
      targetsError: false,
      targets: [],
      targetsList: ["Hoger salaris", "Andere werkgever", "Nieuwe functie", "Vast contract", "Flexibele werktijden/-locatie", "Persoonlijke ontwikkeling"],
      target_day: 0,
      target_month: 0,
      target_year: 0,
      months: {
        1: "Januari",
        2: "Februari",
        3: "Maart",
        4: "April",
        5: "Mei",
        6: "Juni",
        7: "Juli",
        8: "Augustus",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "December"
      },
      years: [],
      todoIcon: '',
      todoHeading: "",
      todoContent: "",
      todoLink: false,
      todoLinkText: '',
      percentage: {
        career_information: 0,
        personal_information: 0,
        qualities: 0,
        targets: 0,
        wishes: 0
      },
      opportunities_count: 0,
      professionsModal: false,
      professions: [],
      positions: null
    };
  },

  mounted() {
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i < currentYear + 10; i++) {
      this.years.push(i);
    }

    this.$store.state.loading = true;
    api.get("/api/user/targets").then(response => {
      this.parseTargets(response);
    }).catch(error => alert('Dashboard.vue - ' + error));
    api.get("/api/user/progress").then(response => {
      this.percentage = {
        personal_information: this.calcPercentage(response.data.values.personal_information, response.data.total.personal_information),
        career_information: this.calcPercentage(response.data.values.career_information, response.data.total.career_information),
        qualities: this.calcPercentage(response.data.values.qualities, response.data.total.qualities),
        wishes: this.calcPercentage(response.data.values.wishes, response.data.total.wishes),
        targets: response.data.values.targets ? 1 : 0
      };
      this.opportunities_count = response.data.values.opportunities_count;

      if (this.opportunities_count > 0) {
        this.todoIcon = 'arrow-breakout';
        this.todoHeading = 'Je hebt een onbekeken kans';
        this.todoContent = 'Er is een werkgever die op basis van jouw wensen en kwaliteiten contact met je legt. Bekijk de kans en bepaal wat je ermee doet.';
        this.todoLink = '/kansen';
        this.todoLinkText = 'Direct bekijken';
      } else if (this.percentage.wishes == 100 && this.percentage.qualities == 100) {
        this.todoIcon = 'wishes-complete';
        this.todoHeading = 'Je staat sterk op de arbeidsmarkt.';
        this.todoContent = 'Je Wishes profiel is compleet. Je wensen en kwaliteiten hebben een podium. Download je profiel in PDF of deel jouw unieke link. Wijzigingen voer je eenvoudig zelf door.';
        this.todoLink = '/profiel';
        this.todoLinkText = 'Direct doen';
      } else if (this.percentage.personal_information > 0) {
        this.todoIcon = 'edit-line';
        this.todoHeading = 'Maak je Wishes profiel.';
        this.todoContent = 'Stel je wensen en toon je kwaliteiten. Het is jouw tijd, kennis en ervaring. Pak en houd de regie. Jij bepaalt, wij ondersteunen.';
        this.todoLink = '/profiel';
        this.todoLinkText = 'Begin nu';
      } else {
        this.todoIcon = 'rocket';
        this.todoHeading = 'Begin hier jouw reis bij Wishes.';
        this.todoContent = 'Elke keer dat je inlogt, leidt dit blokje je naar je eerstvolgende actie. Vul eerst je basisgegevens in en kom daarna hier terug voor je volgende stappen.';
        this.todoLink = '/categorie/1/0';
        this.todoLinkText = 'Begin hier';
      }

      this.$store.state.loading = false;
    }).catch(error => alert('Dashboard.vue - ' + error));
    api.get('/api/professions-all').then(response => {
      this.professions = response.data.map(profession => {
        return {
          id: profession.id,
          text: profession.name,
          usable: profession.usable
        };
      });
    }).catch(error => alert('Professions.vue - get_professions ' + error));
  },

  computed: { ...mapGetters('questionnaire', ['step']),
    ...mapGetters('user', ['user']),

    flash() {
      if (localStorage.getItem('flash')) {
        const message = localStorage.getItem('flash');
        localStorage.removeItem('flash');
        return message;
      }

      return false;
    },

    availableDays() {
      var _this$target_year, _this$target_month;

      let days = new Date((_this$target_year = this.target_year) !== null && _this$target_year !== void 0 ? _this$target_year : new Date().getFullYear(), (_this$target_month = this.target_month) !== null && _this$target_month !== void 0 ? _this$target_month : new Date().getMonth(), 0).getDate();
      return days;
    },

    targetDate() {
      return `${this.target_day}-${this.target_month}-${this.target_year}`;
    },

    formattedTargetDate() {
      const [d, m, y] = this.targetDate.split("-");
      const date = new Date(y, m - 1, d);
      return date.toLocaleString('default', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
    },

    selected_professions: {
      get: function () {
        return [];
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: 17,
          answer: new_value,
          is_current: false
        });
      }
    }
  },
  watch: {
    availableDays(days) {
      if (this.target_day > days) {
        this.target_day = days;
      }
    },

    targets(targets) {
      if ((targets === null || targets === void 0 ? void 0 : targets.length) > 0) {
        this.targetsError = false;
      } else {
        this.targetsError = true;
      }
    },

    targetDate: {
      deep: true,

      handler(date) {
        console.log(date);

        if (this.dateError && date.day > 0 && date.month > 0 && date.year > 0) {
          this.dateError = false;
        }
      }

    }
  },
  methods: { ...mapMutations('questionnaire', ['SET_STEP', 'UPDATE_ANSWER']),

    formatNumber(number) {
      return ("00" + number).slice(-2);
    },

    calculateDaysLeft(date) {
      // reverse date
      date = date.split("-").reverse().join('-');
      let currentDate = new Date();
      let targetDate = new Date(date);
      let difference = targetDate.getTime() - currentDate.getTime();
      return Math.ceil(difference / (1000 * 3600 * 24));
    },

    parseTargets(response) {
      var _data$targets_date;

      const data = response.data;
      this.targets = data.targets;
      [this.target_day, this.target_month, this.target_year] = [0, 0, 0];

      if (((_data$targets_date = data.targets_date) === null || _data$targets_date === void 0 ? void 0 : _data$targets_date.length) == 10) {
        [this.target_day, this.target_month, this.target_year] = data.targets_date.split("-");
      }
    },

    saveTargets() {
      this.dateError = false;
      this.targetsError = false;

      if (this.target_day == 0 || this.target_month == 0 || this.target_year == 0) {
        this.dateError = true;
      } //date in past


      const dateObj = new Date(this.target_year, this.target_month - 1, this.target_day);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (dateObj.getTime() < currentDate.getTime()) {
        this.dateError = true;
      }

      if (this.targets.length == 0) {
        this.targetsError = true;
      }

      if (this.dateError || this.targetsError) return;
      this.saving = true;
      api.post(`/api/user/targets`, {
        date: this.targetDate,
        targets: this.targets
      }).then(response => {
        this.parseTargets(response);
        this.$refs.CloseModal.click();
        this.saving = false;
      }).catch(error => alert('Dashboard.vue - save_targets ' + error));
    },

    calcPercentage(n, m) {
      return (n / m * 100).toFixed(0);
    },

    categoryClick(link) {
      if (!this.position || this.position.answer === "[]") {
        this.openProfessionsModal();
        return false;
      }

      this.$router.push({
        path: link
      });
    },

    changedValue(val) {
      console.log(val);
      this.selected_professions = val;
    },

    saveProfessions() {
      api.post(`/api/categories/2/save_answers`, {
        step: this.step,
        current_subcategory: 0,
        current_step: 0
      }).then(response => {
        this.professionsModal = false;
        this.$router.push({
          path: '/categorie/8'
        });
      });
    },

    openProfessionsModal(id) {
      api.get(`/api/categories/2/0/0`).then(response => {
        this.SET_STEP({
          step: response.data.step
        });
      }).catch(error => alert('Categories.vue - get_category ' + error));
      this.professionsModal = true;
    }

  }
};