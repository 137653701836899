import api from '@/api';
import DeleteProfile from './DeleteProfile.vue';
export default {
  name: "password",

  data() {
    return {
      current_password: "",
      new_password: "",
      confirm_password: "",
      errors: {
        current_password: false,
        new_password: false,
        confirm_password: false
      },
      type: "password",
      icon: "/icons/openbaar-profiel-icon.svg",
      Class: "show",
      success: false,
      saving: false
    };
  },

  methods: {
    submitPassword() {
      this.resetErrors();

      if (this.current_password.length == 0) {
        this.errors.current_password = "Je oude wachtwoord is verplicht.";
      }

      if (this.new_password.length == 0) {
        this.errors.new_password = "Je moet een nieuw wachtwoord invoeren.";
      } else {
        if (this.confirm_password != this.new_password) {
          this.errors.confirm_password = "Je nieuwe wachtwoord komt niet overeen.";
        }
      }

      if (this.countErrors() > 0) return;
      this.saving = true;
      api.post(`/api/user/update_password`, {
        current_password: this.current_password,
        new_password: this.new_password
      }).then(response => {
        if (response.data.success) {
          localStorage.setItem("flash", response.data.success);
          return this.$router.push("/instellingen");
        }

        this.saving = false;
      }).catch(error => {
        this.errors.current_password = error.response.data.message;
      });
    },

    resetErrors() {
      this.errors.current_password = false;
      this.errors.new_password = false;
      this.errors.confirm_password = false;
    },

    countErrors() {
      let errorCount = 0;

      for (const error in this.errors) {
        if (this.errors[error]) {
          errorCount++;
        }
      }

      return errorCount;
    },

    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.icon = "/icons/icon-ww-visibilty.svg";
        this.Class = "hide";
      } else {
        this.type = "password";
        this.icon = "/icons/openbaar-profiel-icon.svg";
        this.Class = "show";
      }
    }

  },
  components: {
    DeleteProfile
  }
};