import api from '@/api';
import MainModal from "@/components/shared/misc/MainModal.vue";
export default {
  name: "DeleteProfile",
  components: {
    MainModal
  },

  data() {
    return {
      current_password: '',
      showDeleteModal: false,
      errors: {
        current_password: false
      }
    };
  },

  methods: {
    removeAccount() {
      this.resetErrors();

      if (this.current_password.length == 0) {
        this.errors.current_password = "Je huidige wachtwoord is verplicht.";
      }

      if (this.countErrors() > 0) return;
      this.saving = true;
      api.post(`/api/user/remove_account`, {
        current_password: this.current_password
      }).then(response => {
        if (response.data.success) {
          localStorage.setItem('flash', response.data.success);
          sessionStorage.removeItem('user');
          return this.$router.push("/login");
        }

        this.saving = false;
        this.showDeleteModal = false;
      }).catch(error => {
        this.errors.current_password = error.response.data.message;
      });
    },

    resetErrors() {
      this.errors.current_password = false;
    },

    countErrors() {
      let errorCount = 0;

      for (const error in this.errors) {
        if (this.errors[error]) {
          errorCount++;
        }
      }

      return errorCount;
    }

  }
};