import NotificationMulti from "@/components/shared/settings/notifications/NotificationMulti.vue";
import NotificationSingle from "@/components/shared/settings/notifications/NotificationSingle.vue";
export default {
  name: "notifications",

  mounted() {
    for (var category in this.notifications) {
      if (this.notifications[category].options.length > 1) {
        this.notifications[category].type = "NotificationMulti";
      } else if (this.notifications[category].options.length == 1) {
        this.notifications[category].type = "NotificationSingle";
      }
    }
  },

  data() {
    return {
      notifications: {
        messages: {
          type: "multi",
          name: "Berichten",
          description: "",
          options: [{
            name: "E-mail",
            toggler: true
          }, {
            name: "SMS",
            toggler: false
          }, {
            name: "Browsermeldingen",
            toggler: false
          }]
        },
        reminders: {
          name: "Herinneringen",
          description: "",
          options: [{
            name: "E-mail",
            toggler: true
          }, {
            name: "SMS",
            toggler: false
          }, {
            name: "Browsermeldingen",
            toggler: false
          }]
        },
        support: {
          name: "Accountondersteuning",
          description: "",
          options: [{
            name: "E-mail",
            toggler: true
          }, {
            name: "SMS",
            toggler: false
          }, {
            name: "Browsermeldingen",
            toggler: false
          }]
        },
        marketing: {
          name: "Accountondersteuning",
          description: "",
          options: [{
            name: "E-mail",
            toggler: true
          }]
        }
      }
    };
  },

  components: {
    NotificationMulti,
    NotificationSingle
  }
};