import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "has-icon"
};
const _hoisted_2 = {
  key: 0,
  class: "hartje"
};
const _hoisted_3 = {
  key: 1,
  class: "label",
  for: ""
};
const _hoisted_4 = ["placeholder"];
const _hoisted_5 = {
  key: 0,
  class: "error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$question, _$options$question2, _$options$question3, _$options$question4, _$options$question5, _$options$question6, _$options$question7, _$options$question8, _$options$question9, _$options$question10;

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_1, [_ctx.$route.params.slug == 2 && !$props.hide_title ? (_openBlock(), _createElementBlock("i", _hoisted_2)) : _createCommentVNode("", true), _createTextVNode(), !$props.hide_title ? (_openBlock(), _createElementBlock("label", _hoisted_3, [(_$options$question = $options.question) !== null && _$options$question !== void 0 && _$options$question.required ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass({
      'error': (_$options$question2 = $options.question) === null || _$options$question2 === void 0 ? void 0 : _$options$question2.error
    })
  }, "* ", 2)) : _createCommentVNode("", true), _createElementVNode("b", null, _toDisplayString((_$options$question3 = $options.question) === null || _$options$question3 === void 0 ? void 0 : _$options$question3.question), 1), (_$options$question4 = $options.question) !== null && _$options$question4 !== void 0 && _$options$question4.required ? (_openBlock(), _createElementBlock("em", {
    key: 1,
    class: _normalizeClass({
      'error': (_$options$question5 = $options.question) === null || _$options$question5 === void 0 ? void 0 : _$options$question5.error
    })
  }, " (verplicht)", 2)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), !$props.hide_title ? _withDirectives((_openBlock(), _createElementBlock("p", {
    key: 2,
    class: "description-sm"
  }, _toDisplayString((_$options$question6 = $options.question) === null || _$options$question6 === void 0 ? void 0 : _$options$question6.short_question_description), 513)), [[_vShow, (_$options$question7 = $options.question) === null || _$options$question7 === void 0 ? void 0 : _$options$question7.short_question_description]]) : _createCommentVNode("", true)])]), _createElementVNode("div", null, [_withDirectives(_createElementVNode("textarea", {
    class: "input",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.answer = $event),
    placeholder: (_$options$question8 = $options.question) === null || _$options$question8 === void 0 ? void 0 : _$options$question8.placeholder
  }, null, 8, _hoisted_4), [[_vModelText, $options.answer]]), (_$options$question9 = $options.question) !== null && _$options$question9 !== void 0 && _$options$question9.error ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString((_$options$question10 = $options.question) === null || _$options$question10 === void 0 ? void 0 : _$options$question10.error), 1)) : _createCommentVNode("", true)])], 64);
}