import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: "PostalCodeTravelTime",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapState('questionnaire', ['answers']),
    ...mapGetters('questionnaire', ['step']),

    hasAnswer() {
      if (!this.answers) return false;
      return this.answers.filter(answer => answer.question_id == this.step.id).length > 0;
    },

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answer: {
      get: function () {
        var _this$question$user_q, _this$question$user_q2;

        const answerField = this.hide_title ? "current" : "answer";
        return (_this$question$user_q = (_this$question$user_q2 = this.question.user_question_answer) === null || _this$question$user_q2 === void 0 ? void 0 : _this$question$user_q2[answerField]) !== null && _this$question$user_q !== void 0 ? _this$question$user_q : '';
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: this.question_id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    },
    answerMinutes: {
      get: function () {
        var _this$question$user_q4, _JSON$parse$minutes, _JSON$parse, _this$question$user_q5;

        const answerField = this.hide_title ? "current" : "answer";

        try {
          var _this$question$user_q3;

          JSON.parse((_this$question$user_q3 = this.question.user_question_answer) === null || _this$question$user_q3 === void 0 ? void 0 : _this$question$user_q3[answerField]);
        } catch (e) {
          return '';
        }

        return (_this$question$user_q4 = this.question.user_question_answer) !== null && _this$question$user_q4 !== void 0 && _this$question$user_q4[answerField] ? (_JSON$parse$minutes = (_JSON$parse = JSON.parse((_this$question$user_q5 = this.question.user_question_answer) === null || _this$question$user_q5 === void 0 ? void 0 : _this$question$user_q5[answerField])) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.minutes) !== null && _JSON$parse$minutes !== void 0 ? _JSON$parse$minutes : '' : '';
      },
      set: function (new_value) {
        let answer = JSON.stringify({
          minutes: new_value,
          postalCode: this.answerPostalCode
        });
        this.UPDATE_ANSWER({
          question_id: this.question_id,
          answer,
          is_current: this.hide_title
        });
        this.answer = answer;
      }
    },
    answerPostalCode: {
      get: function () {
        var _this$question$user_q7, _JSON$parse$postalCod, _JSON$parse2, _this$question$user_q8;

        const answerField = this.hide_title ? "current" : "answer";

        try {
          var _this$question$user_q6;

          JSON.parse((_this$question$user_q6 = this.question.user_question_answer) === null || _this$question$user_q6 === void 0 ? void 0 : _this$question$user_q6[answerField]);
        } catch (e) {
          return '';
        }

        return (_this$question$user_q7 = this.question.user_question_answer) !== null && _this$question$user_q7 !== void 0 && _this$question$user_q7[answerField] ? (_JSON$parse$postalCod = (_JSON$parse2 = JSON.parse((_this$question$user_q8 = this.question.user_question_answer) === null || _this$question$user_q8 === void 0 ? void 0 : _this$question$user_q8[answerField])) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.postalCode) !== null && _JSON$parse$postalCod !== void 0 ? _JSON$parse$postalCod : '' : '';
      },
      set: function (new_value) {
        let answer = JSON.stringify({
          minutes: this.answerMinutes,
          postalCode: new_value
        });
        this.UPDATE_ANSWER({
          question_id: this.question_id,
          answer,
          is_current: this.hide_title
        });
        this.answer = answer;
      }
    }
  },

  mounted() {
    if (this.hide_title) {
      try {
        JSON.parse(this.question.user_question_answer.current);
      } catch (e) {
        this.question.user_question_answer.current = `{"minutes":"","postalCode":""}`;
      }
    }
  },

  methods: { ...mapActions('questionnaire', ['validate']),
    ...mapMutations('questionnaire', ['CREATE_EMPTY_ANSWER', 'UPDATE_ANSWER']),

    validatePostalCode() {
      const postalRegex = /^(?:NL-)?(?:[1-9]\d{3} ?(?:[A-EGHJ-NPRTVWXZ][A-EGHJ-NPRSTVWXZ]|S[BCEGHJ-NPRTVWXZ]))$/i;

      if (postalRegex.test(this.answerPostalCode)) {}
    }

  }
};