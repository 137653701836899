import CtaButton from "@/components/shared/misc/CtaButton.vue";
export default {
  components: {
    CtaButton
  },
  props: {
    show: Boolean,
    header: String,
    disabledFooter: Boolean,
    okayText: String,
    width: {
      type: String,
      default: '600px'
    }
  },
  emits: ['close', 'submit']
};