export default {
  name: "setting",

  data() {
    return {
      role: "werknemer",
      settings: [// {
      //   id: 1,
      //   role: "werknemer",
      //   img: "/icons/meldingen-icon.svg",
      //   name: "Meldingen",
      //   description: "lorem ipsum"
      // },
      {
        id: 0,
        role: "werknemer",
        img: "/icons/user-01.svg",
        name: "Persoonlijke gegevens",
        url: '/categorie/1/0',
        description: "Voor onze communicatie. Wat basis informatie."
      }, {
        id: 1,
        role: "werknemer",
        img: "/icons/briefcase-01.svg",
        name: "Carrière informatie",
        url: '/categorie/13/0',
        description: "Inspiratie ontvangen? Beantwoord enkele vragen."
      }, // {
      //   id: 3,
      //   role: "werknemer",
      //   img: "/icons/bell-02.svg",
      //   name: "Meldingen",
      //   url: '/instellingen/meldingen',
      //   description: "Instellen hoe we communiceren."
      // },
      {
        id: 2,
        role: "werknemer",
        img: "/icons/lock-01.svg",
        name: "Wachtwoord",
        url: '/instellingen/wachtwoord-wijzigen',
        description: "Eenvoudig wachtwoord aanpassen."
      } // {
      //   id: 3,
      //   role: "werknemer",
      //   img: "/icons/wachtwoord-icon.svg",
      //   name: "Profiel verwijderen",
      //   url: '/instellingen/profiel-verwijderen',
      //   description: "Alle data op Wishes verwijderen."
      // },
      // {
      //   id: 3,
      //   role: "werknemer",
      //   img: "/icons/faq-icon-blue.svg",
      //   name: "Profiel-Verwijderen",
      //   description: "lorem ipsum"
      // },
      // {
      //   id: 4,
      //   role: "werkgever",
      //   img: "/icons/icons-werkgevers/facturatie-gegevens-icon.svg",
      //   name: "Facturatie-Gegevens",
      //   description: "lorem ipsum"
      // },
      // {
      //   id: 5,
      //   role: "werkgever",
      //   img: "/icons/icons-werkgevers/wishes-credit-icon.svg",
      //   name: "Wishes-Credits",
      //   description: "lorem ipsum"
      // },
      ]
    };
  }

};