import Select2 from "vue3-select2-component";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      removedValues: [],
      selectedValues: []
    };
  },

  props: ['options', 'settings', 'placeholder', 'modelValue', 'hasUnusable', 'initValue', 'disableInput', 'count', 'answerField'],
  emits: ['changedValue'],
  components: {
    Select2
  },
  computed: { ...mapGetters('user', ['specialSelectValue', 'specialSelectValueCurrent']),

    selectedLabels() {// if (this.answerField && this.answerField === 'current') return this.specialSelectValueCurrent
      // else return this.specialSelectValue
    },

    optionsFiltered() {
      if (this.hasUnusable) {
        return this.options.filter(option => {
          return option.usable == 1;
        });
      } else return this.options;
    },

    settingsObj() {
      if (this.settings) return this.settings;else return {
        tags: true,
        multiple: true
      };
    }

  },
  methods: { ...mapMutations('user', ['SET_SPECIAL_SELECT_VALUE', 'SET_SPECIAL_SELECT_VALUE_CURRENT']),

    mySelectEvent(e) {
      if (!e.selected) return; // if e.text exists, continue

      if (this.selectedValues.find(val => val == e.text || val == e.id)) return;

      if (!e._resultId) {
        if (this.modelValue && !this.modelValue.find(val => val == e.text || val == e.id)) this.modelValue.push(e.text);
      }

      if (this.settingsObj.ajax) {
        this.options.push(e);
      }

      this.selectedValues.push(e.id);
      this.$emit('changedValue', this.selectedValues); // if (this.answerField && this.answerField === 'current') this.SET_SPECIAL_SELECT_VALUE_CURRENT(this.selectedValues)
      // else this.SET_SPECIAL_SELECT_VALUE(this.selectedValues)
    },

    findProfession(idd) {
      var _this$options$find;

      if (!parseInt(idd)) return idd;else return (_this$options$find = this.options.find(({
        id
      }) => id == idd)) === null || _this$options$find === void 0 ? void 0 : _this$options$find.text;
    },

    removeSelection(option) {
      this.selectedValues = this.selectedValues.filter(value => value != option);
      this.$emit('changedValue', this.selectedValues); //
      // if (this.answerField && this.answerField === 'current') this.SET_SPECIAL_SELECT_VALUE(this.selectedValues)
      // else this.SET_SPECIAL_SELECT_VALUE_CURRENT(this.selectedValues)
    },

    inputLostFocus() {
      let searchEl = document.querySelector('.select2-search__field');
      searchEl.placeholder = this.placeholder;
    }

  },
  watch: {
    initValue: function (newVal, oldVal) {
      // add what is missing
      newVal.forEach(el => {
        if (!this.selectedValues.find(val => val == el)) this.selectedValues.push(el);
      }); // remove if it was removed from decision aid

      if (Array.isArray(newVal) && Array.isArray(oldVal) && newVal.length < oldVal.length) {
        let removedValue;
        oldVal.forEach(val => {
          if (!newVal.find(el => el == val)) removedValue = val;
        });
        this.selectedValues = this.selectedValues.filter(value => value != removedValue);
      }
    }
  },

  mounted() {
    try {
      this.selectedValues = JSON.parse(this.initValue);
    } catch (e) {}

    let searchEl = document.querySelector('.select2-search__field');
    searchEl.placeholder = this.placeholder;
    searchEl.addEventListener('focusout', this.inputLostFocus, false);
  },

  updated() {
    let searchEl = document.querySelector('.select2-search__field');
    searchEl.placeholder = this.placeholder;
    searchEl.addEventListener('focusout', this.inputLostFocus, false);
  }

};