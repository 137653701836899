import Dashboard from '../employee/Dashboard.vue';
import EmployerDashboard from '../employers/Dashboard.vue';
import { mapState, mapGetters } from 'vuex';
export default {
  computed: { ...mapGetters('user', ['user_role']),

    dashboardComponent() {
      return this.user_role === 'employer' ? EmployerDashboard : Dashboard;
    }

  }
};