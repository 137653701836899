import api from '@/api';
import router from '@/router';
const state = {
  step: {}
};
const getters = {
  step: state => {
    state.step.map(question => {
      if (!question.user_question_answer) {
        question.user_question_answer = {
          question_id: question.id,
          answer: null,
          current: null
        };
      }

      return question;
    });
    return state.step;
  }
};
const mutations = {
  SET_STEP(state, step) {
    step.step[0].current_answered = step.step[0].id !== 18;
    state.step = step.step;
  },

  UPDATE_ANSWER(state, answer) {
    const questionIndex = state.step.findIndex(question => question.id == answer.question_id);

    if (!state.step[questionIndex].user_question_answer) {
      state.step[questionIndex].user_question_answer = {
        question_id: answer.question_id,
        answer: null,
        current: null
      };
    }

    let answerField = answer.is_current ? "current" : "answer";

    if (answer.updateCurrent) {
      answerField = 'current';
    }

    state.step[questionIndex].user_question_answer[answerField] = answer.answer;
  },

  SET_CURRENT_ANSWERED(state, id) {
    const questionIndex = state.step.findIndex(question => question.id == id);
    state.step[questionIndex].current_answered = true;
  }

};
const actions = {
  async validate({
    state
  }) {
    let errors = 0;
    state.step = state.step.map(question => {
      if (question.required == 0 && !(question.user_question_answer.answer.length > 0 && question.question_type_text === 'Phone')) return question;
      let ans = question.user_question_answer.answer;

      if (ans.length == 0) {
        question.error = "Dit veld is verplicht.";
        errors++;
      } else {
        question.error = "";
      }

      console.log(question.id);

      if (!question.current_answered && question.id === 18) {
        question.current_not_answered = true;
        errors++;
      } else {
        question.current_not_answered = false;
      }

      switch (question.question_type_text) {
        case "MultipleChoice":
          break;

        case "Open":
          break;

        case "YesNo":
          break;

        case "DateOfBirth":
          if (!ans.match(/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/g)) {
            question.error = "Geboortedatum is onjuist (DD-MM-YYYY).";
            errors++;
          }

          break;

        case "Address":
          if (!ans.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)) {
            question.error = "Voer een correct postcode in";
            errors++;
          }

          break;

        case "Phone":
          if (!ans.match(/^[\+]?[(]?[0-9, ,-]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i)) {
            question.error = "Voer een correct telefoonnummer in";
            errors++;
          }

          break;

        case "Number":
          break;

        case "Days":
          break;

        case "MultipleChoiceMulti":
          break;

        case "Properties":
          break;

        case "Professions":
          break;

        case "ProfessionsMulti":
          break;

        case "Textarea":
          break;

        case "Educations":
          break;

        case "Competences":
        case "Skills":
          if (ans.length < 5) {
            question.error = "Voeg minimaal vijf items toe";
            errors++;
          }

          break;

        case "CompetencesExplanation":
          let count = 0;
          Object.keys(ans).forEach(key => {
            if (ans[key]) count++;
          });

          if (count === 0) {
            question.error = "Dit veld is verplicht.";
            errors++;
          }

          break;

        case "PostalCodeTravelTime":
          try {
            JSON.parse(ans);
          } catch (e) {
            question.error = "Voer een correcte postcode in.";
            errors++;
            break;
          }

          const answerParsed = JSON.parse(ans);

          if (answerParsed.minutes.length === 0 || answerParsed.postalCode.length === 0) {
            question.error = "Dit veld is verplicht.";
            errors++;
            break;
          }

          if (!answerParsed.postalCode.match(/^(?:NL-)?(?:[1-9]\d{3} ?(?:[A-EGHJ-NPRTVWXZ][A-EGHJ-NPRSTVWXZ]|S[BCEGHJ-NPRTVWXZ]))$/i)) {
            question.error = "Please insert a postcode in correct format";
            errors++;
          }

          break;
      }

      return question;
    });
    return errors == 0;
  }

};
export default {
  namespaced: true,
  strict: false,
  state,
  getters,
  actions,
  mutations
};