import DashboardCategory from "@/components/employee/DashboardCategory.vue";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import api from '@/api';
import CtaButton from "@/components/shared/misc/CtaButton.vue";
import party from "party-js";
import MultipleSelect from "@/components/shared/misc/MultipleSelect.vue";
import MainModal from "@/components/shared/misc/MainModal.vue";
import WishHelp from "@/components/employee/category/inputs/Wish-Help.vue";
export default {
  name: "Profile",
  computed: { ...mapGetters('questionnaire', ['step']),
    ...mapGetters('user', ['user']),

    checkCompletedProfile() {
      var _this$categories, _this$categories2, _this$categories3;

      let completed_cats = (_this$categories = this.categories) === null || _this$categories === void 0 ? void 0 : _this$categories.filter(cat => cat.state == "COMPLETED");
      return (completed_cats === null || completed_cats === void 0 ? void 0 : completed_cats.length) == ((_this$categories2 = this.categories) === null || _this$categories2 === void 0 ? void 0 : _this$categories2.length) && ((_this$categories3 = this.categories) === null || _this$categories3 === void 0 ? void 0 : _this$categories3.length) > 0;
    },

    publicProfileLink() {
      if (this.checkCompletedProfile) return "/mijn-wishes-profiel";
      return "/voorbeeld-wishes-profiel";
    },

    flash() {
      if (localStorage.getItem('flash')) {
        const message = localStorage.getItem('flash');
        localStorage.removeItem('flash');
        return message;
      }

      return false;
    },

    selected_professions: {
      get: function () {
        return [];
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: 17,
          answer: new_value,
          is_current: false
        });
      }
    }
  },
  methods: { ...mapMutations('questionnaire', ['SET_STEP', 'UPDATE_ANSWER']),

    changedValue(val) {
      this.selected_professions = val;
    },

    saveProfessions() {
      api.post(`/api/categories/2/save_answers`, {
        step: this.step,
        current_subcategory: 0,
        current_step: 0
      }).then(response => {
        this.professionsModal = false;
        this.$router.push({
          path: '/categorie/8'
        });
      });
    },

    openProfessionsModal(id) {
      api.get(`/api/categories/2/0/0`).then(response => {
        this.SET_STEP({
          step: response.data.step
        });
      }).catch(error => alert('Categories.vue - get_category ' + error));
      this.professionsModal = true;
    },

    categoryClick(category) {
      if (category.name === 'Jouw kwaliteiten' && (!this.position || this.position.answer === "[]")) {
        this.openProfessionsModal(category.id);
        return false;
      }

      let link = '/categorie/' + category.id;

      if (category.name === 'Jouw wensen') {
        if (category.state === 'COMPLETED') {
          link = '/categorie/' + category.id + '/samenvatting';
        } else link = '/categorie/' + category.id + '/intro';
      }

      this.$router.push({
        path: link
      });
    },

    celebrate() {
      if (this.completed_cats !== 2 || !this.flash) {
        return false;
      } else {
        let runButton = this.$refs['wishesFilled'];
        party.confetti(runButton, {
          count: 50,
          spread: 45
        });
      }

      clearInterval(this.celebrateInterval);
    },

    copyURL() {
      navigator.clipboard.writeText(window.location.origin + '/profile/' + this.user.uuid);
      this.showCopyToast = true;
      setTimeout(() => {
        this.showCopyToast = false;
      }, 700);
    },

    downloadPdf() {
      window.open(process.env.VUE_APP_API_URL + 'user/public-profile-download/' + this.user.uuid, '_blank');
    }

  },
  components: {
    WishHelp,
    MainModal,
    CtaButton,
    DashboardCategory,
    MultipleSelect
  },

  data() {
    return {
      categories: [],
      completed: false,
      heading: '',
      content: '',
      linkText: '',
      linkUrl: '',
      completed_cats: 0,
      icon: '',
      celebrateInterval: null,
      showCopyToast: false,
      professionsModal: false,
      professions: [],
      position: null
    };
  },

  mounted() {
    this.celebrateInterval = setInterval(this.celebrate, 1000);
    this.$store.state.loading = true;
    api.get("/api/user/progress").then(response => {
      var _this$categories4;

      this.$store.state.loading = false;
      this.categories = response.data.categories;
      if (response.data.total.wishes === response.data.values.wishes) this.categories[0].state = 'COMPLETED';
      if (response.data.total.qualities === response.data.values.qualities) this.categories[1].state = 'COMPLETED';
      this.position = response.data.position;
      this.completed_cats = (_this$categories4 = this.categories) === null || _this$categories4 === void 0 ? void 0 : _this$categories4.filter(cat => cat.state === "COMPLETED").length;

      if (this.completed_cats === 2) {
        this.heading = ' Je Wishes profiel is beschikbaar';
        this.content = 'Via onderstaande knoppen kun je jouw unieke URL kopieren, zodat je het eenvoudig kan delen. Je kan ook de PDF versie van je profiel downloaden.';
        this.linkText = 'Profiel bekijken';
        this.linkUrl = '/mijn-wishes-profiel';
        this.icon = 'profile-progress3';
      } else if (this.completed_cats === 1) {
        this.heading = 'Profiel bijna compleet';
        this.content = 'Je hebt de helft ingevuld voor een compleet Wishes profiel. Ga nu verder met de rest en ervaar je sterkere positie op de arbeidsmarkt.';
        this.linkText = 'Ga verder';
        this.linkUrl = '/categorie/8';
        this.icon = 'profile-progress2';
      } else {
        this.heading = 'Maak je Wishes profiel.';
        this.content = 'Versterk je positie op de arbeidsmarkt. Maak jezelf gelijkwaardiger en verander de dynamiek en beoordelingscriteria.';
        this.linkText = 'Bekijk voorbeeld';
        this.linkUrl = '/profile/e114237a-861f-4ce2-a72f-58143f460e90';
        this.icon = 'profile-progress1';
      }
    }).catch(error => alert('Profile.vue - ' + error));
    api.get('/api/professions-all').then(response => {
      this.professions = response.data.map(profession => {
        return {
          id: profession.id,
          text: profession.name,
          usable: profession.usable
        };
      });
    }).catch(error => alert('Professions.vue - get_professions ' + error));
  }

};