import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  style: {
    "flex": "1"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loader = _resolveComponent("Loader");

  const _component_sidebar = _resolveComponent("sidebar");

  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_Loader), _createElementVNode("div", _hoisted_1, [!$options.isLogin ? (_openBlock(), _createBlock(_component_sidebar, {
    key: 0
  })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock(_component_router_view, {
    key: _ctx.$route.fullPath
  }))])])]);
}