import Address from "@/components/employee/category/question_types/Address.vue";
import DateOfBirth from "@/components/employee/category/question_types/DateOfBirth.vue";
import MultipleChoice from "@/components/employee/category/question_types/MultipleChoice.vue";
import Open from "@/components/employee/category/question_types/Open.vue";
import YesNo from "@/components/employee/category/question_types/YesNo.vue";
import Phone from "@/components/employee/category/question_types/Phone.vue";
import Number from "@/components/employee/category/question_types/Number.vue";
import Days from "@/components/employee/category/question_types/Days.vue";
import MultipleChoiceMulti from "@/components/employee/category/question_types/MultipleChoiceMulti.vue";
import Properties from "@/components/employee/category/question_types/Properties.vue";
import ProfessionsMulti from "@/components/employee/category/question_types/ProfessionsMulti.vue";
import PostalCodeTravelTime from "@/components/employee/category/question_types/PostalCodeTravelTime.vue";
import WishHelp from "../inputs/Wish-Help.vue";
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: "double",

  data() {
    return {
      tabOpened: 'not_provided',
      initialCurrentValue: null
    };
  },

  computed: { ...mapGetters('questionnaire', ['step']),
    currentSituation: {
      get() {
        return this.tabOpened;
      },

      set(new_val) {
        this.SET_CURRENT_ANSWERED(this.step[0].id);
        if (new_val === 'is_same' || new_val === 'not_provided') this.initialCurrentValue = null; // if Days input need to open an array

        if (this.step[0].question_type_text === 'Days') this.initialCurrentValue = [];
        this.UPDATE_ANSWER({
          question_id: this.step[0].id,
          answer: new_val === 'other' ? this.initialCurrentValue : new_val,
          updateCurrent: true
        });
        this.tabOpened = new_val;
      }

    }
  },

  mounted() {
    //find which tab is opened
    const currentAnswer = this.step[0].user_question_answer["current"];
    if (currentAnswer === 'is_same') this.tabOpened = 'is_same';else if (currentAnswer === 'not_provided') this.tabOpened = 'not_provided';else if (!currentAnswer) {
      this.tabOpened = 'not_provided';
      this.initialCurrentValue = [];
    } else if (currentAnswer !== "[]" || Array.isArray(currentAnswer)) {
      this.tabOpened = 'other';
      this.initialCurrentValue = currentAnswer;
    } else {
      this.tabOpened = 'not_provided';
      this.initialCurrentValue = [];
    }

    if (this.step[0].id === 18) {
      this.tabOpened = false;
    }
  },

  methods: { ...mapMutations('questionnaire', ['UPDATE_ANSWER', 'SET_CURRENT_ANSWERED'])
  },
  props: ["index", "key"],
  components: {
    Address,
    DateOfBirth,
    MultipleChoice,
    Open,
    YesNo,
    WishHelp,
    Phone,
    Number,
    Days,
    MultipleChoiceMulti,
    Properties,
    ProfessionsMulti,
    PostalCodeTravelTime
  }
};