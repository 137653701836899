import Accept from "@/components/employee/chances/actions/modal/Accept.vue";
import Reject from "@/components/employee/chances/actions/modal/Reject.vue";
import Edit from "@/components/employee/chances/actions/modal/Edit.vue";
import Withdraw from "@/components/employee/chances/actions/modal/Withdraw.vue";
export default {
  data() {
    return {
      modalTitle: "Aanbod accepteren - afspraak moment kiezen",
      modalTagline: "De werkgever heeft een aantal afspraak momenten voorgesteld. Kies een of meerdere momenten of stel zelf afspraak momenten voor indien de voorgestelde data jou niet uit komen.",
      status: Accept
    };
  },

  components: {
    Accept,
    Reject,
    Edit,
    Withdraw
  }
};