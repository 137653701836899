import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-84836300"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["src", "alt"];
const _hoisted_2 = {
  key: 1,
  class: "cta-button"
};
const _hoisted_3 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");

  return $props.to ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: $props.to,
    class: "cta-button"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true), $props.icon ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: '/icons/' + $props.icon + '.svg',
      alt: $props.icon
    }, null, 8, _hoisted_1)) : _createCommentVNode("", true)]),
    _: 3
  }, 8, ["to"])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true), $props.icon ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: '/icons/' + $props.icon + '.svg',
    alt: $props.icon
  }, null, 8, _hoisted_3)) : _createCommentVNode("", true)]));
}