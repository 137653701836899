// import Open from "@/components/employee/chances/actions/Open.vue";
// import Planned from "@/components/employee/chances/actions/Planned.vue";
// import Scheduled from "@/components/employee/chances/actions/Scheduled.vue";
// import Modal from "@/components/employee/chances/actions/Modal.vue";
export default {
  name: "chanceDetail",
  props: ["chance"],

  data() {
    return {
      jobTitle: "Web Developer",
      status: "open",
      companyName: "Post NL",
      companyInformation: "We bezorgen dagelijks pakketten en vijf dagen per week post. Voor onze klanten zijn wij de onmisbare schakel tussen verzenders en ontvangers en de verbinder tussen de fysieke en digitale wereld.",
      tasks: ["Je geeft technish inzicht", "Je neemt verschillende belangrijke beslissingen"],
      wishes: ["Vast contract", "€5000,- per maand", "40 vakantiedagen per jaar"]
    };
  }

};