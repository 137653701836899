import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "percentage"
};
const _hoisted_2 = {
  key: 0,
  src: "/icons/check.svg",
  alt: "check"
};
const _hoisted_3 = ["aria-valuenow"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["progress-box", $props.percentage == 100 ? 'full' : ''])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["status", $options.classObject])
  }, [_createElementVNode("div", _hoisted_1, [$props.percentage == 100 ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createTextVNode(_toDisplayString($props.percentage) + "%", 1)], 64))]), _createTextVNode(" Ingevuld ")], 2), _createElementVNode("div", {
    class: _normalizeClass(["progress", $props.percentage > 50 ? 'bg-complete-light' : 'bg-incomplete-light'])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["progress-bar", $props.percentage > 50 ? 'bg-complete' : 'bg-incomplete']),
    role: "progressbar",
    style: _normalizeStyle('width: ' + $props.percentage + '%'),
    "aria-valuenow": $props.percentage,
    "aria-valuemin": "0",
    "aria-valuemax": "100"
  }, null, 14, _hoisted_3)], 2)], 2);
}