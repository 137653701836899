import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = ["placeholder"];
const _hoisted_3 = ["type", "placeholder"];
const _hoisted_4 = {
  key: 0,
  class: "error-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $props.type === 'textarea' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("label", null, _toDisplayString($props.label), 1), _createElementVNode("textarea", {
    placeholder: $props.placeholder,
    onInput: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', $event.target.value))
  }, null, 40, _hoisted_2)])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass({
      'input-container': true,
      'error': $props.error
    })
  }, [_createElementVNode("label", null, _toDisplayString($props.label), 1), _createElementVNode("input", {
    type: $props.type ? $props.type : 'text',
    placeholder: $props.placeholder,
    onInput: _cache[1] || (_cache[1] = $event => _ctx.$emit('update:modelValue', $event.target.value))
  }, null, 40, _hoisted_3), $props.error ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.error), 1)) : _createCommentVNode("", true)], 2));
}