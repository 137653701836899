import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "form-content"
};
const _hoisted_2 = {
  class: "has-icon"
};
const _hoisted_3 = {
  key: 0,
  class: "label",
  for: ""
};
const _hoisted_4 = {
  key: 0,
  class: "error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$question, _$options$question2, _$options$question3, _$options$question4, _$options$question5, _$options$question6, _$options$question7, _$options$question8, _$options$question9, _$options$question10;

  const _component_MultipleSelect = _resolveComponent("MultipleSelect");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, [!$props.hide_title ? (_openBlock(), _createElementBlock("label", _hoisted_3, [(_$options$question = $options.question) !== null && _$options$question !== void 0 && _$options$question.required ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass({
      'error': (_$options$question2 = $options.question) === null || _$options$question2 === void 0 ? void 0 : _$options$question2.error
    })
  }, "* ", 2)) : _createCommentVNode("", true), _createElementVNode("b", null, _toDisplayString((_$options$question3 = $options.question) === null || _$options$question3 === void 0 ? void 0 : _$options$question3.question), 1), (_$options$question4 = $options.question) !== null && _$options$question4 !== void 0 && _$options$question4.required ? (_openBlock(), _createElementBlock("em", {
    key: 1,
    class: _normalizeClass({
      'error': (_$options$question5 = $options.question) === null || _$options$question5 === void 0 ? void 0 : _$options$question5.error
    })
  }, " (verplicht)", 2)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), !$props.hide_title ? _withDirectives((_openBlock(), _createElementBlock("p", {
    key: 1,
    class: "description-sm"
  }, _toDisplayString((_$options$question6 = $options.question) === null || _$options$question6 === void 0 ? void 0 : _$options$question6.short_question_description), 513)), [[_vShow, (_$options$question7 = $options.question) === null || _$options$question7 === void 0 ? void 0 : _$options$question7.short_question_description]]) : _createCommentVNode("", true)]), _createElementVNode("div", null, [_createVNode(_component_MultipleSelect, {
    options: $data.professions,
    placeholder: (_$options$question8 = $options.question) === null || _$options$question8 === void 0 ? void 0 : _$options$question8.placeholder,
    hasUnusable: "true",
    onChangedValue: _cache[0] || (_cache[0] = $event => $options.changedValue($event)),
    initValue: $options.selected_professions
  }, null, 8, ["options", "placeholder", "initValue"]), (_$options$question9 = $options.question) !== null && _$options$question9 !== void 0 && _$options$question9.error ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString((_$options$question10 = $options.question) === null || _$options$question10 === void 0 ? void 0 : _$options$question10.error), 1)) : _createCommentVNode("", true)])]);
}