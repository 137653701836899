import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import api from '@/api';
import WishHelp from "@/components/employee/category/inputs/Wish-Help.vue";
export default {
  components: {
    WishHelp
  },

  data() {
    return {
      competences: [],
      explanationsOpened: [],
      competenceSubheaders: [],
      hover: []
    };
  },

  name: "CompetencesExplanation",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapGetters('questionnaire', ['step']),

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answerField() {
      return this.hide_title ? "current" : "answer";
    },

    answer: {
      get: function () {
        var _this$question$user_q, _this$question, _this$question$user_q2;

        return (_this$question$user_q = (_this$question = this.question) === null || _this$question === void 0 ? void 0 : (_this$question$user_q2 = _this$question.user_question_answer) === null || _this$question$user_q2 === void 0 ? void 0 : _this$question$user_q2[this.answerField]) !== null && _this$question$user_q !== void 0 ? _this$question$user_q : "";
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: this.question_id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    },

    explained() {
      let count = 0;
      this.competences.forEach(val => {
        if (this.answer['id_' + val.id] && this.competences.find(item => item.id === val.id)) count++;
      });
      return count + "/" + this.competences.length;
    }

  },
  methods: { ...mapMutations('questionnaire', ['UPDATE_ANSWER']),

    openExplanation(id) {
      this.explanationsOpened.push(id);
      if (!this.answer['id_' + id]) this.answer['id_' + id] = '';
    },

    closeExplanation(id) {
      this.explanationsOpened = this.explanationsOpened.filter(el => el !== id);
      this.answer['id_' + id] = '';
    },

    isOpened(id) {
      return this.explanationsOpened.find(el => el === id);
    },

    inputExplanation(event, id) {
      this.answer['id_' + id] = event.target.value;
    },

    subHeader: function (competence) {
      // If competence starts with 70, it's a knowledge & experience competence
      if (competence.toString().startsWith('70')) {
        return 'Kennis & Ervaring';
      } else if (competence.toString().startsWith('71')) {
        return 'Competenties';
      } // If competence starts with 71, it's a competence
      // let headerParts = competence.split('-')
      // if(!this.competenceSubheaders.find(headerId => headerId === headerParts[0])){
      //     this.competenceSubheaders.push(headerParts[0])
      //     return headerParts[0] == 71 ? 'Kennis & Ervaring' : 'Competenties'
      // }

    }
  },

  mounted() {
    if (this.answer) {
      var _this$question3, _this$question3$user_;

      try {
        var _this$question2, _this$question2$user_;

        JSON.parse((_this$question2 = this.question) === null || _this$question2 === void 0 ? void 0 : (_this$question2$user_ = _this$question2.user_question_answer) === null || _this$question2$user_ === void 0 ? void 0 : _this$question2$user_[this.answerField]);
      } catch (e) {
        this.answer = {};
      }

      this.answer = JSON.parse((_this$question3 = this.question) === null || _this$question3 === void 0 ? void 0 : (_this$question3$user_ = _this$question3.user_question_answer) === null || _this$question3$user_ === void 0 ? void 0 : _this$question3$user_[this.answerField]);
      Object.keys(this.answer).forEach(key => {
        if (this.answer[key]) this.explanationsOpened.push(key.replace("id_", ""));
      });
    } else {
      this.answer = {};
    }

    api.get('/api/user/competences').then(response => {
      const labels = {
        '70': 'Competenties',
        '71': 'Kennis & Ervaring'
      };
      const seenPrefixes = {};
      response.data.forEach(item => {
        const prefix = item.id.split('-')[0];

        if (!seenPrefixes[prefix]) {
          // If it's the first occurrence of the prefix, set the label and mark the prefix as seen
          item.label = labels[prefix];
          seenPrefixes[prefix] = true;
        } else {
          // If the prefix has been seen before, set the label to null
          item.label = null;
        }
      });
      this.competences = response.data;
      this.step[0].user_question_answer.current = this.competences;
    }).catch(error => alert('competences.vue - get_competences ' + error));
  },

  updated() {
    this.competenceSubheaders = [];
  }

};