import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "item"
};
const _hoisted_2 = {
  class: "subcat-nr"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_link, {
    to: $props.disabled ? '' : $options.subcatLink,
    style: _normalizeStyle({
      cursor: $props.disabled ? 'not-allowed' : 'pointer'
    }),
    class: _normalizeClass(["information_buttons", this.class])
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.index), 1), _createTextVNode(" " + _toDisplayString(this.subcategory.name), 1)]),
    _: 1
  }, 8, ["to", "style", "class"])]);
}