import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText } from "vue";
const _hoisted_1 = {
  class: "form-content input-field"
};
const _hoisted_2 = {
  key: 0,
  class: "has-icon"
};
const _hoisted_3 = {
  key: 0,
  class: "label",
  for: ""
};
const _hoisted_4 = ["placeholder", "required"];
const _hoisted_5 = {
  key: 0,
  class: "error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$question, _$options$question2, _$options$question3, _$options$question4, _$options$question5, _$options$question6, _$options$question7;

  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$props.hide_title ? (_openBlock(), _createElementBlock("span", _hoisted_2, [!$props.hide_title ? (_openBlock(), _createElementBlock("label", _hoisted_3, [(_$options$question = $options.question) !== null && _$options$question !== void 0 && _$options$question.required ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass({
      'error': (_$options$question2 = $options.question) === null || _$options$question2 === void 0 ? void 0 : _$options$question2.error
    })
  }, "* ", 2)) : _createCommentVNode("", true), _createElementVNode("b", null, _toDisplayString((_$options$question3 = $options.question) === null || _$options$question3 === void 0 ? void 0 : _$options$question3.question), 1), (_$options$question4 = $options.question) !== null && _$options$question4 !== void 0 && _$options$question4.required ? (_openBlock(), _createElementBlock("em", {
    key: 1,
    class: _normalizeClass({
      'error': (_$options$question5 = $options.question) === null || _$options$question5 === void 0 ? void 0 : _$options$question5.error
    })
  }, " (verplicht)", 2)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), !$props.hide_title ? _withDirectives((_openBlock(), _createElementBlock("p", {
    key: 1,
    class: "description-sm"
  }, _toDisplayString((_$options$question6 = $options.question) === null || _$options$question6 === void 0 ? void 0 : _$options$question6.short_question_description), 513)), [[_vShow, (_$options$question7 = $options.question) === null || _$options$question7 === void 0 ? void 0 : _$options$question7.short_question_description]]) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", null, [_withDirectives(_createElementVNode("input", {
    type: "number",
    min: "0",
    class: "input",
    pattern: "[0-9]*",
    onKeypress: _cache[0] || (_cache[0] = (...args) => $options.onlyNumbers && $options.onlyNumbers(...args)),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.answer = $event),
    placeholder: $options.question.placeholder,
    required: $options.question.required
  }, null, 40, _hoisted_4), [[_vModelText, $options.answer]]), $options.question.error ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($options.question.error), 1)) : _createCommentVNode("", true)])]);
}