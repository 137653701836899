import { mapActions, mapGetters, mapMutations } from 'vuex';
import api from '@/api';
import MultipleSelect from "@/components/shared/misc/MultipleSelect.vue";
export default {
  data() {
    return {
      professions: []
    };
  },

  name: "ProfessionsMulti",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapGetters('questionnaire', ['step']),

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    selected_professions: {
      get: function () {
        var _this$question;

        return (_this$question = this.question) === null || _this$question === void 0 ? void 0 : _this$question.user_question_answer.answer;
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: this.step[this.index].id,
          answer: new_value,
          is_current: this.hide_title
        });
        this.SET_SPECIAL_SELECT_VALUE(new_value);
      }
    }
  },
  methods: { ...mapActions('questionnaire', ['validate']),
    ...mapMutations('questionnaire', ['CREATE_EMPTY_ANSWER', 'UPDATE_ANSWER']),
    ...mapMutations('user', ['SET_SPECIAL_SELECT_VALUE']),

    changedValue(val) {
      this.selected_professions = val;
    }

  },
  components: {
    MultipleSelect
  },

  mounted() {
    try {
      this.question.user_question_answer.answer = JSON.parse(this.question.user_question_answer.answer);
      this.SET_SPECIAL_SELECT_VALUE(this.question.user_question_answer.answer);
    } catch (e) {
      this.question.user_question_answer.answer = [];
    }

    api.get('/api/professions-all').then(response => {
      this.professions = response.data.map(profession => {
        return {
          id: profession.id,
          text: profession.name,
          usable: profession.usable
        };
      });
    }).catch(error => alert('Professions.vue - get_professions ' + error));
  }

};