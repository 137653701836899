import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7d95956c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "has-icon"
};
const _hoisted_2 = {
  key: 0,
  class: "label",
  for: ""
};
const _hoisted_3 = {
  class: "row multimulti"
};
const _hoisted_4 = ["value", "id"];
const _hoisted_5 = ["for"];
const _hoisted_6 = {
  key: 0,
  class: "error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$question, _$options$question2, _$options$question3, _$options$question4, _$options$question5, _$options$question6, _$options$question7, _$options$question8, _$options$question9;

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("span", _hoisted_1, [!$props.hide_title ? (_openBlock(), _createElementBlock("label", _hoisted_2, [(_$options$question = $options.question) !== null && _$options$question !== void 0 && _$options$question.required ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass({
      'error': (_$options$question2 = $options.question) === null || _$options$question2 === void 0 ? void 0 : _$options$question2.error
    })
  }, "* ", 2)) : _createCommentVNode("", true), _createElementVNode("b", null, _toDisplayString((_$options$question3 = $options.question) === null || _$options$question3 === void 0 ? void 0 : _$options$question3.question), 1), (_$options$question4 = $options.question) !== null && _$options$question4 !== void 0 && _$options$question4.required ? (_openBlock(), _createElementBlock("em", {
    key: 1,
    class: _normalizeClass({
      'error': (_$options$question5 = $options.question) === null || _$options$question5 === void 0 ? void 0 : _$options$question5.error
    })
  }, " (verplicht)", 2)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), !$props.hide_title ? _withDirectives((_openBlock(), _createElementBlock("p", {
    key: 1,
    class: "description-sm"
  }, _toDisplayString((_$options$question6 = $options.question) === null || _$options$question6 === void 0 ? void 0 : _$options$question6.short_question_description), 513)), [[_vShow, (_$options$question7 = $options.question) === null || _$options$question7 === void 0 ? void 0 : _$options$question7.short_question_description]]) : _createCommentVNode("", true)]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.step[$props.index].answers, (answer, key) => {
    return _openBlock(), _createElementBlock("div", {
      class: "form-check-field",
      key: key
    }, [_withDirectives(_createElementVNode("input", {
      type: "checkbox",
      class: "form-check-input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.question.user_question_answer[$options.answerField] = $event),
      value: answer.answer.replace(/(\r\n|\n|\r)/gm, ''),
      id: $options.getFieldName(answer.id)
    }, null, 8, _hoisted_4), [[_vModelCheckbox, $options.question.user_question_answer[$options.answerField]]]), _createElementVNode("label", {
      class: "form-check-label",
      for: $options.getFieldName(answer.id)
    }, _toDisplayString(answer.answer), 9, _hoisted_5)]);
  }), 128))]), (_$options$question8 = $options.question) !== null && _$options$question8 !== void 0 && _$options$question8.error ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString((_$options$question9 = $options.question) === null || _$options$question9 === void 0 ? void 0 : _$options$question9.error), 1)) : _createCommentVNode("", true)])], 64);
}