import axios from 'axios';
import router from '@/router';
let instance = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL
});
instance.interceptors.request.use(request => {
  request.headers.common['Accept'] = 'application/json';
  request.headers.common['Content-Type'] = 'application/json';
  let user = JSON.parse(sessionStorage.getItem("user"));

  if (user !== null) {
    request.headers.common['Authorization'] = 'Bearer ' + user.token;
  }

  return request;
});
instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status == 401) {
    sessionStorage.removeItem('user');
    return router.push({
      name: "Login"
    });
  }

  return Promise.reject(error);
});
export default instance;