import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      professionsOpened: false,
      professionExpanded: null,
      selected: []
    };
  },

  props: ['professions', 'modelValue', 'disableInput'],
  emits: ['update:modelValue', 'decisionaid', 'changeval'],
  computed: { ...mapGetters('user', ['specialSelectValue']),

    items() {
      if (!this.professionExpanded) return [];

      for (let v of Object.values(this.professionExpanded)) {
        if (Array.isArray(v)) return v;
      }
    },

    decisionAidValue: {
      get: function () {
        return this.specialSelectValue;
      },
      set: function (new_value) {
        //this.SET_SPECIAL_SELECT_VALUE(this.modelValue)
        this.$emit('decisionaid', this.modelValue);
      }
    }
  },
  watch: {
    professionsOpened() {
      this.fixHeight();
    },

    professionExpanded() {
      this.fixHeight();
    }

  },
  methods: { ...mapMutations('user', ['SET_SPECIAL_SELECT_VALUE']),

    hasItems(obj) {
      if (!obj) return false;

      for (let v of Object.values(obj)) {
        if (Array.isArray(v) && v.length > 0) return true;
      }

      return false;
    },

    optionClicked(event) {
      if (this.disableInput && !this.modelValue.find(item => item == event.target.value)) {
        event.preventDefault();
        event.stopPropagation();
      }

      this.$emit('changeval', event.target.value, event.target.checked);
    },

    checkboxChange(item) {
      //this.SET_SPECIAL_SELECT_VALUE(this.modelValue)
      this.$emit('update:modelValue', this.modelValue);
      this.$emit('decisionaid', item);
    },

    fixHeight() {
      if (window.outerWidth < 991) {
        setTimeout(() => {
          const decisionAidHeight = document.querySelector('.decision-aid').offsetHeight;
          document.querySelector('.form-content').style.paddingBottom = decisionAidHeight + 16 + 'px';
        }, 300);
      }
    }

  },

  mounted() {
    this.fixHeight();
  }

};