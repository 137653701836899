import Single from "@/components/employee/category/category_types/Single.vue";
import Double from "@/components/employee/category/category_types/Double.vue";
import Multi from "@/components/employee/category/category_types/Multi.vue";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import useVuelidate from '@vuelidate/core';
import ProgressBar from "@/components/shared/misc/ProgressBar.vue";
export default {
  name: "QuestionList",
  props: ["current_step"],

  setup() {
    return {
      v$: useVuelidate()
    };
  },

  computed: { ...mapGetters('questionnaire', ['step']),

    button_text() {
      if (this.$parent.saving) return "Aan het opslaan...";

      if (this.$route.params.slug == 1 || this.$route.params.slug == 13) {
        return "Opslaan";
      }

      if (this.$parent.current_step == this.$parent.total_steps - 1 && this.$parent.current_subcategory == this.$parent.subcategories.length - 1) {
        return "Opslaan";
      }

      return "Volgende";
    },

    percentage() {
      if (this.$parent.subcategory.total_questions_answered === 0) return 0;
      return Math.round(this.$parent.subcategory.total_questions_answered / this.$parent.subcategory.total_questions * 100);
    },

    showProgessBar() {
      return this.$route.params.slug && ['2', '8'].includes(this.$route.params.slug);
    }

  },

  data() {
    return {};
  },

  methods: {},
  components: {
    ProgressBar,
    Single,
    Double,
    Multi
  },

  created() {}

};