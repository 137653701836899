export default {
  name: "vacancies",

  data() {
    return {
      vacancies: []
    };
  },

  props: ["Vacancies"]
};