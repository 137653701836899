import CtaButton from "@/components/shared/misc/CtaButton.vue";
import Opportunity from "@/components/employee/partials/opportunity.vue";
import api from "@/api";
export default {
  components: {
    Opportunity,
    CtaButton
  },

  data() {
    return {
      opportunities: [],
      modalOpenedId: null
    };
  },

  computed: {
    inbox() {
      return this.opportunities.filter(opportunity => opportunity.status !== 'archived');
    },

    archived() {
      return this.opportunities.filter(opportunity => opportunity.status === 'archived');
    }

  },
  methods: {
    changed(opportunity, status) {
      if (status && status !== opportunity.status) {
        api.put("/api/opportunities/" + opportunity.id, {
          status
        }).then(response => {
          this.fetchData();
          this.modalOpenedId = null;
        }).catch(error => alert('opportunities.vue - ' + error));
      }
    },

    fetchData() {
      api.get("/api/opportunities").then(response => {
        this.opportunities = response.data;
      }).catch(error => alert('opportunities.vue - ' + error));
    },

    changeModalId(id) {
      this.modalOpenedId = id;
    }

  },

  mounted() {
    this.fetchData();
  }

};