export default {
  props: ['percentage'],
  computed: {
    classObject() {
      return {
        'incomplete': this.percentage < 51,
        'complete': this.percentage > 50,
        'full': this.percentage == 100
      };
    }

  }
};