import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "personal-information box"
};
const _hoisted_2 = {
  class: "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.step, question => {
    return _openBlock(), _createElementBlock("div", {
      class: "col-md-6",
      key: question.id
    }, [(_openBlock(), _createBlock(_resolveDynamicComponent(question.question_type_text), {
      question_id: question.id
    }, null, 8, ["question_id"]))]);
  }), 128))])]);
}