import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/shared/Login.vue';
import LostPassword from '@/components/shared/LostPassword.vue';
import Dashboard from '@/components/shared/Dashboard.vue';
import PublicProfile from '@/components/employee/PublicProfile.vue';
import PublicProfilePreview from '@/components/employee/PublicProfilePreview.vue';
import Categories from '@/components/employee/Categories.vue';
import Wishes from '@/components/employee/Wishes.vue';
import chanceDetail from '@/components/employee/chances/ChanceDetail.vue';
import Settings from '@/components/shared/Settings.vue';
import Notifications from '@/components/shared/settings/Notifications.vue';
import Password from '@/components/shared/settings/Password.vue';
import DeleteProfile from '@/components/shared/settings/DeleteProfile.vue';
import FAQ from '@/components/shared/Faq.vue';
import Profile from '@/components/employee/Profile.vue';
import store from '@/store';
import MyProfile from "@/components/employee/MyProfile.vue";
import Opportunities from "@/components/employee/Opportunities.vue";
import api from "@/api";
import Vacancies from "@/components/employers/Vacancies/Vacancies.vue";
import AddVacancy from "@/components/employers/Vacancies/AddVacancy.vue";
import Candidate from "@/components/employers/Candidates/Candidate.vue";
import Feedback from "@/components/employers/Feedback.vue";
import Widget from "@/components/employers/Widget/Widget.vue";
const routes = [//algemeen
{
  path: '/login',
  name: 'Login',
  component: Login
}, {
  path: '/wachtwoord-vergeten',
  name: 'Wachtwoord vergeten',
  component: LostPassword
}, {
  path: '/',
  alias: '/dashboard',
  name: 'Dashboard',
  component: Dashboard
}, {
  path: '/',
  alias: '/profiel',
  name: 'Profile',
  component: Profile
}, {
  path: '/mijn-wishes-profiel',
  name: 'PublicProfile',
  component: PublicProfile
}, {
  path: '/voorbeeld-wishes-profiel',
  name: 'PublicProfilePreview',
  component: PublicProfilePreview
}, {
  path: '/instellingen',
  name: 'Settings',
  component: Settings
}, {
  path: '/faq',
  name: 'faq',
  component: FAQ
}, // werkgever
{
  path: '/vacatures',
  name: 'Vacatures',
  component: Vacancies
}, {
  path: '/feedback',
  name: 'Feedback',
  component: Feedback
}, {
  path: '/widget',
  name: 'Widget',
  component: Widget
}, {
  path: '/vacatures/nieuw',
  name: 'Vacature toevoegen',
  component: AddVacancy
}, {
  path: '/sollicitaties/:id/:uuid',
  name: 'Sollicitatie',
  component: Candidate
}, //werknemer
{
  path: '/categorie/2/intro',
  name: "intro",
  component: Wishes
}, {
  path: '/categorie/2/samenvatting',
  name: "summary",
  component: Categories
}, {
  path: '/categorie/:slug',
  name: "questions",
  component: Categories
}, {
  path: '/categorie/:slug/:subcat',
  name: "questions2",
  component: Categories
}, {
  path: '/categorie/:slug/:subcat/:step',
  name: "questions3",
  component: Categories
}, {
  path: '/kansen',
  name: 'Kansen',
  component: Opportunities
}, {
  path: '/kansen/kans',
  name: "kans",
  component: chanceDetail
}, {
  path: '/instellingen/meldingen',
  name: 'notifications',
  component: Notifications
}, {
  path: '/instellingen/wachtwoord-wijzigen',
  name: 'password',
  component: Password
}, {
  path: '/instellingen/profiel-verwijderen',
  name: 'DeleteProfile',
  component: DeleteProfile
}, {
  path: '/profile/:uuid',
  name: 'PublicProfile',
  component: PublicProfile
}, {
  path: '/my-profile',
  name: 'MyProfile',
  component: MyProfile
}];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'Wachtwoord vergeten' && to.name !== 'PublicProfile' && !sessionStorage.user) {
    next({
      name: 'Login'
    });
  } else {
    // store.commit('user/SET_USER', sessionStorage.getItem('user'))
    store.commit('SET_ROUTE', to);
    next();
  }
});
router.afterEach((to, from, next) => {
  if (sessionStorage.user) api.get("/api/user/notification-count").then(response => {
    store.commit('user/SET_NOTIFICATIONS', response.data);
  }).catch(error => console.log(error));
});
export default router;