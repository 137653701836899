import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import api from '@/api';
import WishHelp from "@/components/employee/category/inputs/Wish-Help.vue";
import DecisionAid from "@/components/shared/misc/DecisionAid.vue";
import MultipleSelect from "@/components/shared/misc/MultipleSelect.vue";
export default {
  data() {
    return {
      value: "",
      competences: [],
      suggested_competences: [],
      disableInput: false
    };
  },

  name: "competences",
  props: {
    index: Number,
    modelValue: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapGetters('user', ['specialSelectValue']),
    ...mapGetters('questionnaire', ['step']),

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answerField() {
      return this.hide_title ? "current" : "answer";
    },

    competence: {
      get: function () {
        var _this$question$user_q, _this$question$user_q2;

        return (_this$question$user_q = (_this$question$user_q2 = this.question.user_question_answer) === null || _this$question$user_q2 === void 0 ? void 0 : _this$question$user_q2[this.answerField]) !== null && _this$question$user_q !== void 0 ? _this$question$user_q : [];
      },
      set: function (new_value) {
        this.disableInput = new_value && new_value.length >= 10;
        if (this.disableInput) new_value = new_value.slice(0, 10);
        this.UPDATE_ANSWER({
          question_id: this.question_id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    },

    settings() {
      return {
        tags: true,
        multiple: true,
        tokenSeparators: [','],
        disabled: this.disableInput,
        language: {
          noResults: () => "Geen resultaten gevonden.",
          searching: () => "Aan het zoeken...",
          inputTooShort: () => "Voer minstens drie tekens in"
        },
        maximumSelectionLength: 5,
        minimumInputLength: 3,
        dataType: 'json',
        ajax: {
          dataType: 'json',
          delay: 400,
          transport: function (params, success, failure) {
            let data;
            api.get('/api/competences?q=' + params.data.q + '&page=' + params.data.page).then(response => {
              success(response.data);
            }).catch(error => alert('competences.vue - get_competences ' + error));
            return data;
          },
          data: function (params) {
            return {
              q: params.term,
              page: params.page || 1
            };
          },
          processResults: function (data, params) {
            let page = params.page || 1;
            return {
              results: data.data.map(property => {
                return {
                  id: property.id,
                  text: property.name
                };
              }),
              pagination: {
                more: page < data.last_page
              }
            };
          },
          cache: true
        }
      };
    }

  },
  components: {
    WishHelp,
    MultipleSelect,
    DecisionAid
  },
  methods: { ...mapMutations('user', ['SET_SPECIAL_SELECT_VALUE']),
    ...mapMutations('questionnaire', ['UPDATE_ANSWER']),

    changedValue(val) {
      this.competence = val;
      this.SET_SPECIAL_SELECT_VALUE(this.competence);
    },

    useDecisionAid(selected) {
      this.competences.push({
        id: selected.id,
        text: selected.name
      });
    },

    changeval(val, isNew) {
      if (isNew) this.competence.push(parseInt(val));else this.competence = this.competence.filter(edu => edu !== parseInt(val));
      this.competence.push(123123123);
      this.competence = this.competence.filter(edu => edu !== 123123123);
      this.SET_SPECIAL_SELECT_VALUE(this.competence);
    }

  },

  mounted() {
    try {
      let answers = JSON.parse(this.question.user_question_answer[this.answerField]);
      let answerText = JSON.parse(this.question.user_question_answer.answer_text);
      this.question.user_question_answer[this.answerField] = answers;
      this.disableInput = answers && answers.length >= 10;
      answers.forEach((answer, index) => {
        this.competences.push({
          id: answer,
          text: answerText[index]
        });
      });
      this.SET_SPECIAL_SELECT_VALUE(this.competence);
    } catch (e) {
      this.question.user_question_answer[this.answerField] = [];
    }

    api.get('/api/competences-suggestions').then(response => {
      this.suggested_competences = response.data;
    }).catch(error => alert('competences.vue - get_competences ' + error));
  }

};