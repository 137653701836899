import { createStore } from 'vuex'; // import createPersistedState from 'vuex-persistedstate'

import user from './modules/user';
import questionnaire from './modules/questionnaire';
import faq from './modules/faq';
export default createStore({
  plugins: [// createPersistedState()
  ],
  state: {
    route: null,
    loading: false
  },
  mutations: {
    SET_ROUTE(state, route) {
      state.route = route;
    }

  },
  actions: {
    init({
      dispatch
    }) {// dispatch('questionnaire/get_categories');
      // dispatch('questionnaire/get_questions');
      // dispatch('questionnaire/get_properties');
      // dispatch('questionnaire/get_professions');
      // dispatch('faq/get_questions');
    }

  },
  modules: {
    user,
    questionnaire,
    faq
  }
});