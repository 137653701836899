import MainModal from "@/components/shared/misc/MainModal.vue";
import CtaButton from "@/components/shared/misc/CtaButton.vue";
export default {
  data() {
    return {
      status: null,
      isOverflowing: false,
      expanded: false
    };
  },

  components: {
    CtaButton,
    MainModal
  },
  props: ['opportunity', 'modalOpenedId'],
  computed: {
    pillClass() {
      if (this.opportunity.archived) {
        return;
      }

      if (this.opportunity.status === 0) {
        return 'info';
      } else if (this.opportunity.status === 1 || this.opportunity.status === 2 || this.opportunity.status === 5) {
        return 'warning';
      }
    }

  },

  mounted() {
    if (0 > this.$refs.explanation.clientHeight - this.$refs.explanation.scrollHeight) {
      this.isOverflowing = true;
    }
  },

  methods: {
    expand() {
      this.expanded = !this.expanded;
      if (this.expanded) this.$refs.explanation.style.height = 'auto';else this.$refs.explanation.style.height = '70px';
    }

  }
};