import api from '@/api';
import router from '@/router';
const state = {
  loading: false,
  error: null,
  success: null,
  opportunities: 0,
  specialSelectValue: null
};
const getters = {
  user: state => {
    if (!sessionStorage.getItem('user')) return null;else return JSON.parse(sessionStorage.getItem('user'));
  },
  user_role: state => {
    if (!sessionStorage.getItem('user')) return null;else return JSON.parse(sessionStorage.getItem('user')).type_id === 3 ? 'employer' : 'employee';
  },
  authenticated: state => state.user !== null,
  opportunities: state => state.opportunities,
  specialSelectValue: state => state.specialSelectValue,
  specialSelectValueCurrent: state => state.specialSelectValueCurrent
};
const mutations = {
  SET_USER(state, user) {
    state.error = null;
    state.user = user;
  },

  SET_NOTIFICATIONS(state, data) {
    state.opportunities = data;
  },

  SET_SPECIAL_SELECT_VALUE(state, data) {
    if (data === '[]') {
      data = [];
    }

    state.specialSelectValue = data;
  },

  SET_SPECIAL_SELECT_VALUE_CURRENT(state, data) {
    state.specialSelectValueCurrent = data;
  }

};
const actions = {
  async login({
    commit,
    state,
    rootState,
    dispatch
  }, user) {
    // rootState.loading = true;
    state.loading = true;
    await api.get('/sanctum/csrf-cookie');
    api.post("/api/user/login", {
      email: user.email,
      password: user.password
    }).then(function (response) {
      if (response.data.error) {
        state.error = response.data.error;
        state.loading = false;
        return;
      }

      commit('SET_USER', response.data);
      sessionStorage.user = JSON.stringify(response.data);
      state.loading = false;
      dispatch('init', null, {
        root: true
      }); // rootState.loading = true;

      router.push({
        name: "Dashboard"
      });
    }).catch(function (error) {
      alert('login error: ' + error);
    });
  },

  async refresh({
    commit,
    state,
    rootState,
    dispatch
  }) {
    // await api.get('/sanctum/csrf-cookie');
    return api.post("/api/user/refreshUser").then(function (response) {
      commit('SET_USER', response.data);
      console.log(response);
      console.log(JSON.parse(sessionStorage.getItem("user")));
      sessionStorage.user = JSON.stringify(response.data);
    }).catch(function (error) {
      alert('login error: ' + error);
    });
  },

  async resetPassword({
    commit,
    state,
    rootState,
    dispatch
  }, email) {
    // rootState.loading = true;
    state.loading = true;
    api.post("/api/user/reset-password", {
      email: email
    }).then(function (response) {
      state.success = response.data.success;
      console.log(response);
      state.loading = false;
    }).catch(function (error) {
      alert('reset password error: ' + error);
    });
  },

  logout({
    commit
  }) {
    api.get('/api/user/logout').then(function () {
      commit('SET_USER', null);
      sessionStorage.removeItem('user');
      return router.push({
        name: "Login"
      });
    });
  }

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};