import { mapActions, mapState } from 'vuex';
export default {
  methods: { ...mapActions('user', ['login']),

    formSubmit() {
      this.login(this.user);
    }

  },
  computed: { ...mapState('user', ['loading', 'error', 'success']),

    button_text() {
      if (this.loading) return "Aan het inloggen...";
      return "Inloggen";
    }

  },

  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      siteUrl: process.env.VUE_APP_SITE_URL,
      canLogin: false
    };
  },

  mounted() {
    if (this.$route.query.password_set !== undefined) {
      this.canLogin = true;
    }
  }

};