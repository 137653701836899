export default {
  computed: {
    percentage() {
      if (this.index > this.current_subcategory + 1) return 0;
      if (this.current_subcategory + 1 > this.index) return 100;
      return (this.current_step + 1) / this.subcategory.total_questions * 100;
    },

    class() {
      if (this.current_subcategory + 1 > this.index) return "done";
      if (this.current_subcategory + 1 == this.index) return "active";
      return "";
    },

    subcatLink() {
      if (this.subcategory.name == "Samenvatting") {
        return `/categorie/2/samenvatting`;
      }

      if (this.$route.name == "summary") {
        return `/categorie/2/${this.index - 1}`;
      }

      return `/categorie/${this.$route.params.slug}/${this.index - 1}`;
    }

  },
  name: "subcategory",
  props: ["disabled", "subcategory", "current_subcategory", "index", "current_step", "total_steps"]
};