import "core-js/modules/es.array.reduce.js";
import api from "@/api";
import CtaButton from "@/components/shared/misc/CtaButton.vue";
import MainModal from "@/components/shared/misc/MainModal.vue";
import Input from "@/components/shared/misc/Input.vue";
import opportunity from "@/components/employee/partials/opportunity.vue";
import Select from "@/components/shared/misc/Select.vue";
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  name: "PublicProfile",
  components: {
    QuillEditor,
    Select,
    Input,
    MainModal,
    CtaButton
  },

  data() {
    return {
      summary: [],
      primary: [],
      basic: [],
      qualities: [],
      professions: [],
      summary_answers: [],
      important_question_count: 0,
      approachedBefore: 0,
      professions_all: [],
      tab: 1,
      showOpportunityModal: false,
      showOpportunitySubmittedModal: false,
      showCopyToast: false,
      personal_info: {
        post_code: '',
        position: '',
        hours: 0,
        important_wishes: 0,
        minutes_away: ''
      },
      companyName: '',
      fullName: '',
      email: '',
      phoneNumber: '',
      importantWishes: '',
      opportunity_position: null,
      explanation: '',
      errors: {},
      validations: [{
        rules: ['required'],
        name: 'companyName'
      }, {
        rules: ['required'],
        name: 'fullName'
      }, {
        rules: ['required', 'email'],
        name: 'email'
      }, {
        rules: ['required', 'phoneNumber'],
        name: 'phoneNumber'
      }, {
        rules: ['required'],
        name: 'opportunity_position'
      }, {
        rules: ['required'],
        name: 'importantWishes'
      }],
      helperData: {
        skills: [],
        competences: [],
        explainedSkills: [],
        explainedCompetences: []
      }
    };
  },

  props: ['uuid'],
  computed: {
    opportunity() {
      return opportunity;
    },

    profileURL() {
      return window.location.origin + '/profile/' + this.$props.uuid;
    },

    isPrivate() {
      return this.$route.fullPath === '/my-profile';
    },

    quilModules() {
      return {
        handlers: {
          'link': function (value) {
            if (value) {
              let href = prompt('Enter the URL');

              if (!/^https?:\/\//i.test(href)) {
                href = 'https://' + href;
              }

              this.quill.format('link', href);
            } else {
              this.quill.format('link', false);
            }
          }
        }
      };
    }

  },
  methods: {
    parseAnswer(answer) {
      console.log(typeof answer, answer);
      if (answer.includes("is_same")) return 'Hetzelfde als gewenst';else if (answer.includes("not_provided")) return 'Niet opgegeven';

      try {
        answer = JSON.parse(answer);
        if (answer && answer.minutes) return answer.minutes + ' min. vanaf ' + answer.postalCode;
        return answer.join(', ').replace(/, ([^,]*)$/, ' en $1');
      } catch (e) {
        return answer;
      }
    },

    parseAnswerOrTags(answer_text, answerWhole) {
      try {
        answer_text = JSON.parse(answer_text); //for explanations

        let html = '';
        let questionType = answerWhole.question.question_type_text;
        console.log(this.helperData);

        if (questionType === 'Competences') {
          return '<div class="tags">' + this.helperData.competences.map(a => `<span class='pill success explain inline'>${a}</span>`).join('') + '</div>';
        } else if (questionType === 'Skills') {
          return '<div class="tags">' + this.helperData.skills.map(a => `<span class='pill success explain inline'>${a}</span>`).join('') + '</div>';
        } else if (questionType === 'CompetencesExplanation') {
          let explanations = JSON.parse(answerWhole.current);
          explanations.forEach(explanation => {
            if (answer_text['id_' + explanation.id]) html += `<span class='pill success explain'>${explanation.text}</span>` + answer_text['id_' + explanation.id] + '<br/>';
          });
          return html;
        } else if (questionType === 'Educations') {
          return '<div class="tags">' + answer_text.map(a => `<span class="pill info education-pill">${a}</span>`).join('') + '</div>';
        }

        return '<div class="tags">' + answer_text.map(a => `<span>${a}</span>`).join('') + '</div>';
      } catch (e) {
        return '<p>' + answer_text + '</p>';
      }
    },

    parseName(name) {
      if (name == "Primair" || name == "Secundair") {
        return `${name}e wensen`;
      }

      return name;
    },

    parseImportant(important) {
      if (important) return "Ja";
      return "Nee";
    },

    checkIsSame(a, b) {
      console.log('checkIsSame', a);
      if (a == "Nee" || a == "Ja") return a;
      if (a == b) return "Hetzelfde als gewenst";
      return a;
    },

    clickedTab(tab) {
      this.tab = tab;
      let ref = 'tab' + tab;

      if (this.$refs[ref].length) {
        let scrollLength = this.$refs[ref][0].getBoundingClientRect().top + window.pageYOffset - this.$refs['head'].clientHeight;
        if (this.$route.fullPath === '/my-profile') scrollLength -= 60;
        window.scrollTo(0, scrollLength);
      } else {
        window.scrollTo(0, 110);
        this.handleScroll();
      }
    },

    handleScroll(e) {
      if ((document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) && this.$refs['publicProfile']) {
        this.$refs['publicProfile'].classList.add("scrolled");
        this.$refs['body'].style.marginTop = this.$refs['head'].offsetHeight + 'px';
      } else {
        var _this$$refs$publicPro;

        (_this$$refs$publicPro = this.$refs['publicProfile']) === null || _this$$refs$publicPro === void 0 ? void 0 : _this$$refs$publicPro.classList.remove("scrolled");
        this.$refs['body'].style.marginTop = 0;
      }
    },

    copyURL() {
      navigator.clipboard.writeText(this.profileURL);
      this.showCopyToast = true;
      setTimeout(() => {
        this.showCopyToast = false;
      }, 700);
    },

    downloadPdf() {
      window.open(process.env.VUE_APP_API_URL + 'user/public-profile-download/' + this.$props.uuid, '_blank');
    },

    nl2br(str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return '';
      }

      var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },

    submit() {
      this.errors = {};
      this.validations.forEach(validation => {
        validation.rules.forEach(rule => {
          let error = {};

          if (rule === 'required') {
            var _this$validation$name;

            error[validation.name] = 'Verplicht veld';
            if (!((_this$validation$name = this[validation.name]) !== null && _this$validation$name !== void 0 && _this$validation$name.length)) this.errors = { ...this.errors,
              ...error
            };
          } else if (rule === 'email') {
            error[validation.name] = 'Voer een correct e-mailadres in';
            if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,7}$/.test(this[validation.name])) this.errors = { ...this.errors,
              ...error
            };
          } else if (rule === 'phoneNumber') {
            error[validation.name] = 'Voer een correct telefoonnummer in';
            if (!/^[\+]?[(]?[0-9, ,-]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(this[validation.name])) this.errors = { ...this.errors,
              ...error
            };
          }
        });
      });

      if (Object.keys(this.errors).length === 0) {
        api.post(`/api/opportunities`, {
          user_uuid: this.$route.params.uuid,
          companyName: this.companyName,
          fullName: this.fullName,
          email: this.email,
          phoneNumber: this.phoneNumber,
          importantWishes: this.importantWishes,
          explanation: this.explanation,
          opportunity_position: this.opportunity_position
        }).then(response => {
          this.showOpportunityModal = false;
          this.showOpportunitySubmittedModal = true;
        }).catch(error => alert('Dashboard.vue - save_targets ' + error));
      }
    }

  },

  mounted() {
    this.$store.state.loading = true;
    let uuid = this.$props.uuid ? this.$props.uuid : this.$route.params.uuid;
    api.get('/api/user/public-profile/' + uuid).then(response => {
      var _response$data, _response$data$primar, _response$data$primar2, _response$data$summar, _response$data$summar2, _response$data$summar3, _response$data$summar4, _this$qualities$, _this$qualities$2, _competenciesAnswer$, _competenciesAnswer$$, _this$qualities$3, _skillsAnswer$, _skillsAnswer$$user_q;

      this.summary = response.data.summary;
      this.primary = response.data.primary;
      this.basic = response.data.basic;
      this.qualities = (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.qualities;
      this.professions = response.data.professions;
      this.summary_answers = response.data.summary_answers;
      this.personal_info.hours = (_response$data$primar = response.data.primary.find(x => x.id === 20)) === null || _response$data$primar === void 0 ? void 0 : _response$data$primar.user_question_answer.answer;
      this.personal_info.position = (_response$data$primar2 = response.data.primary.find(x => x.id === 17)) !== null && _response$data$primar2 !== void 0 && _response$data$primar2.user_question_answer.answer_text ? JSON.parse(response.data.primary.find(x => x.id === 17).user_question_answer.answer_text).join(', ') : '';
      this.personal_info.important_wishes = response.data.important_wishes_count;
      this.personal_info.minutes_away = (_response$data$summar = response.data.summary[0].questions.find(x => x.id === 73)) !== null && _response$data$summar !== void 0 && _response$data$summar.user_question_answer.answer_text ? JSON.parse((_response$data$summar2 = response.data.summary[0].questions.find(x => x.id === 73)) === null || _response$data$summar2 === void 0 ? void 0 : _response$data$summar2.user_question_answer.answer_text).minutes : '';
      this.personal_info.post_code = (_response$data$summar3 = response.data.summary[0].questions.find(x => x.id === 73)) !== null && _response$data$summar3 !== void 0 && _response$data$summar3.user_question_answer.answer_text ? JSON.parse((_response$data$summar4 = response.data.summary[0].questions.find(x => x.id === 73)) === null || _response$data$summar4 === void 0 ? void 0 : _response$data$summar4.user_question_answer.answer_text).postalCode : '';
      this.important_question_count = this.summary.map(s => s.questions.filter(q => {
        var _q$user_question_answ;

        return (_q$user_question_answ = q.user_question_answer) === null || _q$user_question_answ === void 0 ? void 0 : _q$user_question_answ.important;
      }).length).reduce((a, b) => a + b, 0);
      this.approachedBefore = response.data.approached_before;
      this.professions_all = response.data.professions_all; // explained questions

      let explanationAnswer = (_this$qualities$ = this.qualities[0]) === null || _this$qualities$ === void 0 ? void 0 : _this$qualities$.questions.filter(question => question.question_type_text === 'CompetencesExplanation');
      let allExplanationAnswers, allExplanation;

      if (explanationAnswer && explanationAnswer.length) {
        var _explanationAnswer$, _explanationAnswer$$u, _explanationAnswer$2, _explanationAnswer$2$;

        allExplanation = JSON.parse((_explanationAnswer$ = explanationAnswer[0]) === null || _explanationAnswer$ === void 0 ? void 0 : (_explanationAnswer$$u = _explanationAnswer$.user_question_answer) === null || _explanationAnswer$$u === void 0 ? void 0 : _explanationAnswer$$u.current);
        allExplanationAnswers = JSON.parse((_explanationAnswer$2 = explanationAnswer[0]) === null || _explanationAnswer$2 === void 0 ? void 0 : (_explanationAnswer$2$ = _explanationAnswer$2.user_question_answer) === null || _explanationAnswer$2$ === void 0 ? void 0 : _explanationAnswer$2$.answer);
      } else {
        allExplanation = [];
        allExplanationAnswers = [];
      }

      allExplanation.map(item => {
        let typeId = item.id.split("-");
        let content = this.nl2br(allExplanationAnswers['id_' + item.id]);
        if (typeId[0] == 70 && content) this.helperData.explainedCompetences.push({
          title: item.text,
          content
        });else if (content) this.helperData.explainedSkills.push({
          title: item.text,
          content
        });
      });
      let competenciesAnswer = (_this$qualities$2 = this.qualities[0]) === null || _this$qualities$2 === void 0 ? void 0 : _this$qualities$2.questions.filter(question => question.question_type_text === 'Competences');
      if (competenciesAnswer && competenciesAnswer.length) competenciesAnswer = JSON.parse((_competenciesAnswer$ = competenciesAnswer[0]) === null || _competenciesAnswer$ === void 0 ? void 0 : (_competenciesAnswer$$ = _competenciesAnswer$.user_question_answer) === null || _competenciesAnswer$$ === void 0 ? void 0 : _competenciesAnswer$$.answer_text); // this.helperData.competences = competenciesAnswer.filter(item => !this.helperData.explainedCompetences.find(comp => comp.title === item))

      this.helperData.competences = competenciesAnswer;
      let skillsAnswer = (_this$qualities$3 = this.qualities[0]) === null || _this$qualities$3 === void 0 ? void 0 : _this$qualities$3.questions.filter(question => question.question_type_text === 'Skills');
      if (skillsAnswer && skillsAnswer.length) skillsAnswer = JSON.parse((_skillsAnswer$ = skillsAnswer[0]) === null || _skillsAnswer$ === void 0 ? void 0 : (_skillsAnswer$$user_q = _skillsAnswer$.user_question_answer) === null || _skillsAnswer$$user_q === void 0 ? void 0 : _skillsAnswer$$user_q.answer_text); // this.helperData.skills = skillsAnswer.filter(item => !this.helperData.explainedSkills.find(comp => comp.title === item))

      this.helperData.skills = skillsAnswer;
      this.$store.state.loading = false;
      if (this.$route.query.open) this.showOpportunityModal = true;
    }).catch(error => alert('Categories.vue - get_summary ' + error));
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
    document.querySelector("body").style.backgroundColor = "var(--light-blue-background)";
  },

  destroyed() {
    document.querySelector("body").style.backgroundColor = "unset";
    window.removeEventListener('scroll', this.handleScroll);
  }

};