import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "my-profile"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PublicProfile = _resolveComponent("PublicProfile");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_PublicProfile, {
    uuid: _ctx.user.uuid
  }, null, 8, ["uuid"])]);
}