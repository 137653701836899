export default {
  name: "DashboardCategory",
  props: ["category", "index"],
  computed: {
    isCompleted() {
      return this.category.state == 'COMPLETED';
    },

    icon() {
      return require('@/assets/kwaliteiten-icon.png');
    },

    class() {
      if (this.isCompleted) return 'active completed';
      return 'active';
    },

    path() {
      if (this.category.id === 2) {
        if (this.isCompleted) {
          return '/categorie/2/samenvatting';
        }

        return '/categorie/2/intro';
      }

      return '/categorie/' + this.category.id;
    }

  },
  methods: {}
};