import setting from "@/components/shared/settings/Setting.vue";
import { useRoute } from 'vue-router';
export default {
  mounted() {
    this.name = useRoute().name;
  },

  data() {
    return {
      name: ''
    };
  },

  computed: {
    flash() {
      if (localStorage.getItem('flash')) {
        const message = localStorage.getItem('flash');
        localStorage.removeItem('flash');
        return message;
      }

      return false;
    }

  },
  components: {
    setting
  }
};