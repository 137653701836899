import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "bg-box widget-gray"
};
const _hoisted_2 = {
  class: "widget-title"
};
const _hoisted_3 = {
  class: "links"
};
const _hoisted_4 = {
  key: 0,
  href: "#",
  "data-bs-toggle": "modal",
  "data-bs-target": "#targetsModal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_progress_bar = _resolveComponent("progress-bar");

  const _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.name), 1), _createVNode(_component_progress_bar, {
    percentage: $options.percentageComputed
  }, null, 8, ["percentage"]), _createElementVNode("div", _hoisted_3, [$props.name === 'Doelen' ? (_openBlock(), _createElementBlock("a", _hoisted_4, _toDisplayString($options.linkText), 1)) : $props.name === 'Kwaliteiten' ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    href: "#",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.$emit('kwaliteitenClick'), ["prevent"]))
  }, _toDisplayString($options.linkText), 1)) : (_openBlock(), _createBlock(_component_router_link, {
    key: 2,
    to: $props.link
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($options.linkText), 1)]),
    _: 1
  }, 8, ["to"]))])]);
}