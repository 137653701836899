import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      value: ""
    };
  },

  name: "Number",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapState('questionnaire', ['answers']),
    ...mapGetters('questionnaire', ['step']),

    hasAnswer() {
      if (!this.answers) return false;
      return this.answers.filter(answer => answer.question_id == this.step.id).length > 0;
    },

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answer: {
      get() {
        const answerField = this.hide_title ? "current" : "answer";
        return this.question.user_question_answer[answerField];
      },

      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: this.question.id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    }
  },
  methods: { ...mapActions('questionnaire', ['validate']),
    ...mapMutations('questionnaire', ['CREATE_EMPTY_ANSWER', 'UPDATE_ANSWER']),

    onlyNumbers(event) {
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault();
      }
    }

  }
};