import api from '@/api';
import router from '@/router';
const state = {
  allQuestions: []
};
const getters = {
  questions: state => state.allQuestions
};
const mutations = {
  SET_QUESTIONS(state, questions) {
    state.allQuestions = questions;
  }

};
const actions = {
  init({
    dispatch
  }) {},

  async get_questions({
    commit
  }) {
    await api.get('/sanctum/csrf-cookie');
    api.get("/api/faq_json").then(function (response) {
      commit('SET_QUESTIONS', response.data);
    }).catch(function (error) {
      alert('faq set_questions error: ' + error);
    });
  }

};
export default {
  namespaced: true,
  strict: false,
  state,
  getters,
  actions,
  mutations
};