import api from "@/api";
export default {
  data() {
    return {
      graphData: [],
      footerDate: '',
      currentWeek: 0
    };
  },

  methods: {
    fetchData() {
      api.get("/api/user/profile-visits/" + this.currentWeek).then(response => {
        this.graphData = [];
        this.footerDate = new Intl.DateTimeFormat("nl", {
          month: "long",
          day: "numeric"
        }).format(new Date(response.data[0].date));
        let maxValue = Math.max(Math.max(...response.data.map(o => o.views)), Math.max(...response.data.map(o => o.opportunities)));
        response.data.forEach(day => {
          this.graphData.push({
            views: day.views,
            viewsPercentage: day.views ? day.views / maxValue * 100 : 0,
            opportunities: day.opportunities,
            opportunitiesPercentage: day.opportunities ? day.opportunities / maxValue * 100 : 0,
            day: new Date(day.date).getDate()
          });
        });
      }).catch(error => alert('profile_visits_graph.vue - ' + error));
    },

    switchWeek(changeBy) {
      this.currentWeek = this.currentWeek + changeBy;
      this.fetchData();
    }

  },

  mounted() {
    for (let i = 0; i < 7; i++) {
      this.graphData.push({
        views: 0,
        viewsPercentage: 0,
        opportunities: 0,
        opportunitiesPercentage: 0,
        day: ''
      });
    }

    this.fetchData();
  }

};