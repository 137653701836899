import api from '@/api';
export default {
  name: "Summary",
  props: ["summary"],
  methods: {
    parseAnswer(answer) {
      if (answer.includes("is_same")) return 'Hetzelfde als gewenst';

      try {
        answer = JSON.parse(answer);
        if (answer && answer.minutes) return answer.minutes + ' min. vanaf ' + answer.postalCode;
        return answer.join(', ').replace(/, ([^,]*)$/, ' en $1');
      } catch (e) {
        return answer;
      }
    },

    checkIsSame(a, b) {
      if (a.includes("not_provided")) {
        return "Niet opgegeven";
      }

      if (a == "Nee" || a == "Ja") return a;
      if (a == b) return "Hetzelfde als gewenst";
      return a;
    },

    finish() {
      if (this.important.length == 0) {}

      if (this.$route.name == "summary") {
        this.saving = true;
        api.post(`/api/categories/summary`, {
          important: this.important
        }).then(response => {
          const data = response.data;
          this.saving = false;
          this.$refs.CloseModal.click();
          localStorage.setItem('flash', 'Jouw wensen zijn succesvol opgeslagen.');
          return this.$router.push("/profiel");
        }).catch(error => alert('Categories.vue - save_answers ' + error));
      }
    }

  },

  data() {
    return {
      important: [],
      saving: false
    };
  },

  mounted() {
    api.get(`/api/categories/summary_important`).then(response => {
      this.important = response.data;
    }).catch(error => alert('error'));
  }

};