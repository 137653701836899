import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-72954cd6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "modal-container"
};
const _hoisted_2 = {
  class: "modal-header"
};
const _hoisted_3 = {
  class: "modal-body"
};
const _hoisted_4 = {
  key: 0,
  class: "modal-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: "modal"
  }, {
    default: _withCtx(() => [$props.show ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "modal-mask",
      style: _normalizeStyle({
        '--modal-width': $props.width
      })
    }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString($props.header), 1), _createElementVNode("img", {
      src: "/icons/close-modal.svg",
      alt: "close",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('close'))
    })]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), !$props.disabledFooter ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "footer", {}, () => {
      var _$props$okayText;

      return [_createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('close')),
        class: "text-button-l"
      }, "Annuleren"), _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('submit')),
        class: "primary-button",
        icon: "arrow-right-large"
      }, _toDisplayString((_$props$okayText = $props.okayText) !== null && _$props$okayText !== void 0 ? _$props$okayText : 'Versturen'), 1)];
    }, true)])) : _createCommentVNode("", true)])], 4)) : _createCommentVNode("", true)]),
    _: 3
  });
}