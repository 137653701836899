import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-6d66b8b8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "modal fade",
  id: "tutorialModal",
  tabindex: "-1",
  "aria-labelledby": "tutorialModalLabel",
  "aria-hidden": "true"
};
const _hoisted_2 = {
  class: "modal-dialog modal-md modal-dialog-centered"
};
const _hoisted_3 = {
  class: "modal-content"
};
const _hoisted_4 = {
  class: "modal-header"
};
const _hoisted_5 = {
  class: "modal-title",
  id: "tutorialModalLabel"
};
const _hoisted_6 = {
  type: "button",
  ref: "CloseModal",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  "aria-label": "Close"
};
const _hoisted_7 = {
  class: "modal-body"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  key: 0,
  id: "tutorial-dots"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = ["innerHTML"];
const _hoisted_12 = {
  class: "modal-footer"
};
const _hoisted_13 = {
  type: "button",
  style: {
    "margin-left": "auto"
  },
  "data-bs-dismiss": "modal",
  class: "primary-button"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h5", _hoisted_5, [_createTextVNode("Hoe werkt Wishes? "), _createElementVNode("span", null, "- Stap " + _toDisplayString($data.currentStep + 1) + "/" + _toDisplayString($data.steps.length), 1)]), _createElementVNode("button", _hoisted_6, null, 512)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
    src: `/img/tutorial/${$options.step.image}`,
    alt: ""
  }, null, 8, _hoisted_8), $options.stepArr.length > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.stepArr, (s, index) => {
    return _openBlock(), _createElementBlock("span", {
      key: index,
      class: _normalizeClass({
        'active': index == $data.currentSubStep
      }),
      onClick: $event => $options.goTo(index)
    }, null, 10, _hoisted_10);
  }), 128))])) : _createCommentVNode("", true), _createElementVNode("strong", null, _toDisplayString($options.step.title), 1), _createElementVNode("p", {
    innerHTML: $options.step.content
  }, null, 8, _hoisted_11)]), _createElementVNode("div", _hoisted_12, [_withDirectives(_createElementVNode("button", {
    type: "button",
    onClick: _cache[0] || (_cache[0] = $event => $options.prevStep()),
    class: "secondary-button"
  }, "Vorige", 512), [[_vShow, $data.currentStep > 0]]), _withDirectives(_createElementVNode("button", {
    type: "button",
    style: {
      "margin-left": "auto"
    },
    onClick: _cache[1] || (_cache[1] = $event => $options.nextStep()),
    class: "primary-button"
  }, "Volgende", 512), [[_vShow, $data.currentStep < $data.steps.length - 1]]), _withDirectives(_createElementVNode("button", _hoisted_13, "Sluiten", 512), [[_vShow, $data.currentStep == $data.steps.length - 1]])])])])]);
}