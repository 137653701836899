import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      days: ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"],
      parts: ["Ochtend", "Middag", "Avond", "Nacht"],
      loaded: false
    };
  },

  name: "Days",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapGetters('questionnaire', ['step']),

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answerField() {
      return this.hide_title ? "current" : "answer";
    }

  },
  methods: { ...mapActions('questionnaire', ['validate']),
    ...mapMutations('questionnaire', ['CREATE_EMPTY_ANSWER', 'UPDATE_ANSWER']),

    getFieldName(answer_id) {
      if (this.hide_title) return "answer_current_" + answer_id;
      return "answer_" + answer_id;
    },

    orderInput(input) {
      if (typeof input === 'string') return input;
      const sorter = {
        "maandag": 1,
        "dinsdag": 2,
        "woensdag": 3,
        "donderdag": 4,
        "vrijdag": 5,
        "zaterdag": 6,
        "zondag": 7,
        "ochtend": 8,
        "middag": 9,
        "avond": 10,
        "nacht": 11
      };
      return input === null || input === void 0 ? void 0 : input.sort(function sortByDay(a, b) {
        let day1 = a.toLowerCase();
        let day2 = b.toLowerCase();
        return sorter[day1] - sorter[day2];
      });
    }

  },

  mounted() {
    try {
      this.question.user_question_answer[this.answerField] = JSON.parse(this.question.user_question_answer[this.answerField]);
    } catch (e) {
      this.question.user_question_answer[this.answerField] = [];
    }
  },

  watch: {
    'question.user_question_answer.answer'(newQuestion, oldQuestion) {
      if (newQuestion !== this.orderInput(newQuestion) && typeof newQuestion == 'object') this.question.user_question_answer.answer = this.orderInput(newQuestion);
    },

    'question.user_question_answer.current'(newQuestion, oldQuestion) {
      if (newQuestion !== this.orderInput(newQuestion) && typeof newQuestion == 'object') this.question.user_question_answer.current = this.orderInput(newQuestion);
    }

  }
};