import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: "MultipleChoice",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapGetters('questionnaire', ['step']),

    hasAnswer() {
      if (!this.step) return false;
      return this.answers.filter(answer => answer.question_id == this.step.id).length > 0;
    },

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answerField() {
      return this.hide_title ? "current" : "answer";
    },

    answer: {
      get: function () {
        var _this$question$user_q, _this$question, _this$question$user_q2;

        let answer = (_this$question$user_q = (_this$question = this.question) === null || _this$question === void 0 ? void 0 : (_this$question$user_q2 = _this$question.user_question_answer) === null || _this$question$user_q2 === void 0 ? void 0 : _this$question$user_q2[this.answerField]) !== null && _this$question$user_q !== void 0 ? _this$question$user_q : '';
        if (answer && Array.isArray(answer) && answer.length === 0) answer = '';

        try {
          answer = answer.replace(/(?:\\[rn])+/g, "");
        } catch (e) {}

        return answer;
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: this.question.id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    }
  },
  methods: { ...mapMutations('questionnaire', ['CREATE_EMPTY_ANSWER', 'UPDATE_ANSWER'])
  }
};