import "../public/scss/style.scss";
import sidebar from "@/components/layouts/Sidebar.vue";
import Loader from "@/components/layouts/Loader.vue";
import { mapGetters, mapState, mapActions } from 'vuex';
export default {
  name: "/App",
  components: {
    sidebar,
    Loader
  },
  computed: { ...mapState('user', ['user']),

    isLogin() {
      return this.$route.name === "Login" || this.$route.name === "Wachtwoord vergeten" || this.$route.name === "PublicProfile";
    }

  }
};