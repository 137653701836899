import { mapGetters } from "vuex";
export default {
  name: "wish-help",
  props: ['label'],

  data() {
    return {
      open: false
    };
  },

  computed: { ...mapGetters('questionnaire', ['step']),

    helpText() {
      var _this$step$0$titel_de, _this$step$;

      if (this.$route.params.slug == "8") return (_this$step$0$titel_de = (_this$step$ = this.step[0]) === null || _this$step$ === void 0 ? void 0 : _this$step$.titel_description) !== null && _this$step$0$titel_de !== void 0 ? _this$step$0$titel_de : "Help mij met deze vraag";
      return "Help mij met deze wens";
    }

  },
  methods: {
    toggle() {
      this.open = !this.open;
    }

  }
};