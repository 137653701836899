import { mapGetters, mapMutations } from 'vuex';
import api from '@/api';
import MultipleSelect from "@/components/shared/misc/MultipleSelect.vue";
export default {
  data() {
    return {
      value: "",
      properties: []
    };
  },

  name: "Properties",
  // props: ["index"],
  props: {
    index: Number,
    modelValue: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapGetters('questionnaire', ['step']),

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answerField() {
      return this.hide_title ? "current" : "answer";
    },

    property: {
      get: function () {
        var _this$question;

        return (_this$question = this.question) === null || _this$question === void 0 ? void 0 : _this$question.user_question_answer[this.answerField];
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: this.question_id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    }
  },
  components: {
    MultipleSelect
  },
  methods: { ...mapMutations('questionnaire', ['UPDATE_ANSWER']),
    ...mapMutations('user', ['SET_SPECIAL_SELECT_VALUE', 'SET_SPECIAL_SELECT_VALUE_CURRENT']),

    changedValue(val) {
      console.log('test');
      this.property = val;
    }

  },

  mounted() {
    let newProperties = [];

    try {
      let answerText = [];
      let answers = JSON.parse(this.question.user_question_answer[this.answerField]);
      if (!this.hide_title) answerText = JSON.parse(this.question.user_question_answer.answer_text);else answerText = JSON.parse(this.question.user_question_answer.current_text);
      if (answers.length > 0 && answers !== 'not_provided') this.question.user_question_answer[this.answerField] = answers;
      console.log(answers);
      answers.forEach((answer, index) => {
        newProperties.push({
          id: answer,
          text: answerText[index]
        });
      });
      console.log(this.answerField);
      if (this.answerField === 'answer') this.SET_SPECIAL_SELECT_VALUE(this.question.user_question_answer[this.answerField]);else this.SET_SPECIAL_SELECT_VALUE_CURRENT(this.question.user_question_answer[this.answerField]);
    } catch (e) {
      if (!this.hide_title) this.question.user_question_answer[this.answerField] = [];
    }

    api.get('/api/properties').then(response => {
      let propertyList = response.data.map(property => {
        return {
          id: property.id,
          text: property.name,
          usable: property.usable
        };
      });
      this.properties = propertyList.sort((a, b) => a.text > b.text ? 1 : -1);
    }).catch(error => alert('properties.vue - get_properties ' + error));
  }

};