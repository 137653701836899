import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: "Textarea",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },
  computed: { ...mapState('questionnaire', ['answers']),
    ...mapGetters('questionnaire', ['step']),

    hasAnswer() {
      if (!this.answers) return false;
      return this.answers.filter(answer => answer.question_id == this.step.id).length > 0;
    },

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answer: {
      get: function () {
        var _this$question$user_q, _this$question$user_q2;

        const answerField = this.hide_title ? "current" : "answer";
        return (_this$question$user_q = (_this$question$user_q2 = this.question.user_question_answer) === null || _this$question$user_q2 === void 0 ? void 0 : _this$question$user_q2[answerField]) !== null && _this$question$user_q !== void 0 ? _this$question$user_q : '';
      },
      set: function (new_value) {
        this.UPDATE_ANSWER({
          question_id: this.question_id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    }
  },
  methods: { ...mapActions('questionnaire', ['validate']),
    ...mapMutations('questionnaire', ['CREATE_EMPTY_ANSWER', 'UPDATE_ANSWER'])
  }
};