import { mapActions, mapGetters } from 'vuex';
export default {
  name: "sidebar",

  data() {
    return {
      Role: "werknemer",
      sidebarOpen: true,
      sidebarToggleText: "Uitgeklapt",
      hovered: false
    };
  },

  computed: { ...mapGetters('user', ['opportunities', 'user', 'user_role']),

    route() {
      return this.$route.name;
    },

    role() {
      console.log(this.user_role);
      return this.user_role;
    },

    showOpportunitiesCount() {
      if (this.user && this.opportunities) {
        return this.opportunities;
      } else return false;
    }

  },
  methods: { ...mapActions('user', ['logout']),

    toggleSidebar(hover) {
      this.hovered = hover;
      this.calculateButtonText();
    },

    sidebarToggler() {
      this.sidebarOpen = !this.sidebarOpen;
      this.hovered = false;
      this.calculateButtonText();
    },

    calculateButtonText() {
      if (this.sidebarOpen == true) {
        this.sidebarToggleText = "Uitgeklapt";
        this.sidebarToggleIcon = "open";
      } else {
        this.sidebarToggleText = "Ingeklapt";
        this.sidebarToggleIcon = "close";
      }
    },

    hideMobile() {
      document.querySelector('.navbar-collapse').classList.remove('show');
    }

  },
  components: {}
};