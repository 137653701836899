import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: "YesNo",
  props: {
    index: Number,
    hide_title: {
      type: Boolean,
      default: false
    },
    question_id: Number
  },

  data() {
    return {
      options: ["Ja", "Nee"]
    };
  },

  computed: { ...mapGetters('questionnaire', ['step']),

    hasAnswer() {
      if (!this.answers) return false;
      return this.answers.filter(answer => answer.question_id == this.step.id).length > 0;
    },

    question() {
      return this.step.find(question => question.id == this.question_id);
    },

    answer: {
      get: function () {
        const answerField = this.hide_title ? "current" : "answer";

        if (answerField === "answer") {
          if (!this.question.user_question_answer["answer"] || this.question.user_question_answer["answer"] === 'not_provided') return '';
        } else {
          if (!this.question.user_question_answer["current"] || this.question.user_question_answer["current"] === 'not_provided') return '';else if (Array.isArray(this.question.user_question_answer["current"]) && this.question.user_question_answer["current"].length === 0) {
            return '';
          }
        }

        return this.question.user_question_answer[answerField];
      },
      set: function (new_value) {
        console.log(new_value);
        this.UPDATE_ANSWER({
          question_id: this.step[this.index].id,
          answer: new_value,
          is_current: this.hide_title
        });
      }
    }
  },
  methods: { ...mapMutations('questionnaire', ['UPDATE_ANSWER'])
  }
};