/* eslint-disable vue/no-unused-components */
import Address from "@/components/employee/category/question_types/Address.vue";
import DateOfBirth from "@/components/employee/category/question_types/DateOfBirth.vue";
import MultipleChoice from "@/components/employee/category/question_types/MultipleChoice.vue";
import Open from "@/components/employee/category/question_types/Open.vue";
import YesNo from "@/components/employee/category/question_types/YesNo.vue";
import Phone from "@/components/employee/category/question_types/Phone.vue";
import Number from "@/components/employee/category/question_types/Number.vue";
import Days from "@/components/employee/category/question_types/Days.vue";
import MultipleChoiceMulti from "@/components/employee/category/question_types/MultipleChoiceMulti.vue";
import Properties from "@/components/employee/category/question_types/Properties.vue";
import Professions from "@/components/employee/category/question_types/Professions.vue";
import ProfessionsMulti from "@/components/employee/category/question_types/ProfessionsMulti.vue";
import PostalCodeTravelTime from "@/components/employee/category/question_types/PostalCodeTravelTime.vue";
import { mapGetters } from 'vuex';
export default {
  name: "Multi",
  // props: ["questions", "index"],
  computed: { ...mapGetters('questionnaire', ['step'])
  },
  components: {
    Address,
    DateOfBirth,
    MultipleChoice,
    Open,
    YesNo,
    Phone,
    Number,
    Days,
    MultipleChoiceMulti,
    Properties,
    Professions,
    ProfessionsMulti,
    PostalCodeTravelTime
  }
};