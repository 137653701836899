import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "category_top"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  class: "pill warning"
};
const _hoisted_4 = {
  key: 1,
  class: "pill success"
};
const _hoisted_5 = {
  class: "links"
};
const _hoisted_6 = {
  key: 0,
  href: "#"
};
const _hoisted_7 = {
  key: 1,
  href: "#"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["category", this.class])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    src: this.icon,
    alt: ""
  }, null, 8, _hoisted_2), !$options.isCompleted ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Niet ingevuld")) : _createCommentVNode("", true), $options.isCompleted ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Ingevuld")) : _createCommentVNode("", true)]), _createElementVNode("h3", null, _toDisplayString($props.category.name), 1), _withDirectives(_createElementVNode("p", null, _toDisplayString($props.category.description_filled_in), 513), [[_vShow, $options.isCompleted]]), _withDirectives(_createElementVNode("p", null, _toDisplayString($props.category.description_fill_in), 513), [[_vShow, !$options.isCompleted]]), _createElementVNode("div", _hoisted_5, [!$options.isCompleted ? (_openBlock(), _createElementBlock("a", _hoisted_6, "Invullen")) : _createCommentVNode("", true), $options.isCompleted ? (_openBlock(), _createElementBlock("a", _hoisted_7, "Updaten")) : _createCommentVNode("", true)])], 2);
}