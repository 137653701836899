import Question from '@/components/shared/faq/Question.vue';
import { mapGetters, mapState } from 'vuex';
import api from '@/api';
export default {
  name: "faq",
  components: {
    Question
  },
  computed: { ...mapGetters('user', ['user'])
  },

  data() {
    return {
      questions: []
    };
  },

  mounted() {
    api.get("/api/faq/questions").then(response => this.questions = response.data).catch(error => alert('Faq.vue - ' + error));
  }

};