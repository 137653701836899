import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$label;

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("a", {
    id: "wish-help",
    href: "#",
    onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => $options.toggle && $options.toggle(...args), ["prevent"])),
    class: _normalizeClass({
      active: $data.open
    })
  }, _toDisplayString((_$props$label = $props.label) !== null && _$props$label !== void 0 ? _$props$label : $options.helpText), 3), _createElementVNode("div", _hoisted_1, [_createElementVNode("p", null, [_renderSlot(_ctx.$slots, "default")])])]);
}